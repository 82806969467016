import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from '../Configuration/APIConfig';
import axios from "axios";
import auth from "../Authentication/Auth";
import Modal from 'react-bootstrap/Modal';
import TopHeader from "./TopHeader";
const initialLoginValues = {
    email: '',
    password: ''
};
const initialRegisterValues = {
    name: '',
    email: '',
    password: '',
    phoneNumber: '',
    roleName: 'CUSTOMER',
    domainURL: ''
};
export default function Header() {
    const [loginValues, setLoginValues] = useState(initialLoginValues);
    const [registerValues, setRegisterValues] = useState(initialRegisterValues);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [status, setStatus] = useState("");
    const [registerSuccess, setRegisterSuccess] = useState("");
    const [registerError, setRegisterError] = useState("");
    const [loginShow, setLoginShow] = useState(false);
    const handleLoginClose = () => setLoginShow(false);
    const handleLoginShow = () => { setLoginShow(true); setRegisterShow(false); }

    const [registerShow, setRegisterShow] = useState(false);
    const handleRegisterClose = () => setRegisterShow(false);
    const handleRegisterShow = () => { setRegisterShow(true); setLoginShow(false); }

    const applicationAPI = (url = config.APIACTIVATEURL + config.LOGINUSER) => {
        return {
            userlogin: (newRecord) => axios.post(url, newRecord),
            register: (newRecord) => axios.post(config.APIACTIVATEURL + config.REGISTERUSER, newRecord)
        };
    };
    const handleLoginInputChange = (e) => {
        const { name, value } = e.target;
        setLoginValues({
            ...loginValues,
            [name]: value,
        });
    };
    const handleRegisterInputChange = (e) => {
        const { name, value } = e.target;
        setRegisterValues({
            ...registerValues,
            [name]: value,
        });
    };
    const loginValidate = () => {
        let temp = {};
        temp.email = loginValues.email == '' ? false : true;
        temp.password = loginValues.password == '' ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x == true);
    };
    const registerValidate = () => {
        let temp = {};
        temp.name = registerValues.name == '' ? false : true;
        temp.email = registerValues.email == '' ? false : true;
        temp.password = registerValues.password == '' ? false : true;
        temp.phoneNumber = registerValues.phoneNumber == '' ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x == true);
    };
    const handleRegisterSubmit = (e) => {
        e.preventDefault();
        if (registerValidate()) {
            try {
                setBtnSubmit(false);
                const formData = new FormData()
                formData.append("email", registerValues.email)
                formData.append("userName", registerValues.email)
                formData.append("password", registerValues.password)
                formData.append("phoneNumber", registerValues.phoneNumber)
                formData.append("name", registerValues.name)
                formData.append("roleName", registerValues.roleName)
                formData.append("domainURL", window.location.origin)
                formData.append("isActive", true)
                applicationAPI()
                    .register(formData)
                    .then((res) => {
                        console.log(res.data)
                        if (res.data.statusCode === 200) {
                            setBtnSubmit(true);
                            resetForm();
                            setRegisterSuccess(res.data.response.data)
                            setRegisterError('')
                        } else {
                            setRegisterError(res.data.response.data);
                            setRegisterSuccess('')
                            setBtnSubmit(true);
                        }
                    })
                    .catch(function (error) {
                        setRegisterError(error);
                        setRegisterSuccess('')
                        setBtnSubmit(true);
                    });
            } catch (err) {
                setBtnSubmit(true);
                setRegisterSuccess('')
                setRegisterError('Please check the mandatory fields');
            }
        } else {
            setBtnSubmit(true);
            setRegisterError('Please check the mandatory fields');
            setRegisterSuccess('')
        }
    };
    const handleLoginSubmit = (e) => {
        e.preventDefault();
        if (loginValidate()) {
            try {
                setBtnSubmit(false);
                const formData = new FormData()
                formData.append("email", loginValues.email)
                formData.append("password", loginValues.password)
                checkUser(formData);
            } catch (err) {
                setBtnSubmit(true);
                setStatus('Please check the mandatory fields');
            }
        } else {
            setBtnSubmit(true);
            setStatus('Please check the mandatory fields');
        }
    };
    const checkUser = (loginData) => {
        applicationAPI()
            .userlogin(loginData)
            .then((res) => {
                var responsdeData = res.data.response;
                console.log(responsdeData)
                if (responsdeData.succeeded === true) {
                    setBtnSubmit(true);
                    resetForm();
                    setLoginShow(false); setRegisterShow(false);
                    auth.ulogin(() => {
                        localStorage.setItem('userId', responsdeData.data.userId);
                        localStorage.setItem('name', responsdeData.data.name);
                        //localStorage.setItem('email', res.data.email);
                        //localStorage.setItem('mobile', res.data.mobile);
                        localStorage.setItem('roleName', responsdeData.data.role);
                        localStorage.setItem('userToken', responsdeData.data.token);
                        localStorage.setItem('tokenexpiration', responsdeData.data.expiration);
                    });
                } else {
                    setStatus(responsdeData.message);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                setStatus(error);
                setBtnSubmit(true);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] == false ? ' form-control-danger' : '';
    const resetForm = () => {
        setLoginValues(initialLoginValues);
        setRegisterValues(initialRegisterValues)
        setStatus('');
    }
    return (
        <div>
        <TopHeader></TopHeader>
        <div className="header header-light">
            <div className="container">
                <nav id="navigation" className="navigation navigation-landscape">
                    <div className="nav-header">
                        <Link to={"/"} className="nav-brand static-show"><img src={"/assets/img/logo.png"} className="logo" alt /></Link>
                        <Link to={"/"} className="nav-brand mob-show"><img src={"/assets/img/logo.png"} className="logo" alt /></Link>
                        <div className="nav-toggle" />
                        <div className="mobile_nav">
                            <ul>
                                <li>
                                    <Link to={"/"} className="bg-light-primary text-primary rounded" data-bs-toggle="modal" data-bs-target="#login"><i className="fa-regular fa-circle-user fs-6" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="nav-menus-wrapper" style={{ transitionProperty: 'none' }}>
                        <ul className="nav-menu">
                            <li><Link to={"/searchflights"}><span class="me-2"><img src={"/assets/images/icons/flight.png"} width={36}></img></span>Flights<span className="submenu-indicator" /></Link></li>
                            <li><Link to={"/searchhotels"}><span class="me-2"><img src={"/assets/images/icons/hotel.png"} width={36}></img>Hotels</span><span className="submenu-indicator" /></Link></li>
                            <li><Link to={"/tours"}><span class="me-2"><img src={"/assets/images/icons/holidays.png"} width={36}></img>Holidays</span><span className="submenu-indicator" /></Link></li>
                            <li><Link to={"/searchcars"}><span class="me-2"><img src={"/assets/images/icons/car.png"} width={36}></img>Cars</span><span className="submenu-indicator" /></Link></li>
                            <li><Link to={"/search-bus"}><span class="me-2"><img src={"/assets/images/icons/bus.png"} width={36}></img>Bus</span><span className="submenu-indicator" /></Link></li>
                            <li><Link to={"/search-activities"}><i class="fa-solid fa-skating me-2" style={{fontSize:"22px"}}></i>Activities</Link></li>
                        </ul>
                        {localStorage.getItem("roleName") === "CUSTOMER" ?
                                <ul className="nav-menu nav-menu-social align-to-right">
                                    <li className="list-buttons me-2">
                                        <Link to={"/profile"} className="bg-primary"><i className="fa-regular fa-circle-user fs-6 me-2" />My Account</Link>
                                    </li></ul>
                                : <ul className="nav-menu nav-menu-social align-to-right">
                                    <li className="list-buttons me-2">
                                        <button onClick={handleLoginShow} className="btn btn-danger"><i className="fa-regular fa-circle-user fs-6 me-2" />Login Or Register</button>
                                    </li>
                                </ul>}
                    </div>
                </nav>
            </div>
            {/* Log In Modal */}
            <Modal show={loginShow} onHide={handleLoginClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Sign In</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-login-form py-4 px-md-3 px-0">
                        {status !== "" ? <div class="alert alert-info shadow-md"> <i class="fas fa-check-circle"></i> {status} </div> : ""}
                        <form onSubmit={handleLoginSubmit} autoComplete="off" noValidate>
                            <div className="form-floating mb-4">
                                <input className={'form-control' + applyErrorClass('email')} name="email" type="text" value={loginValues.email} onChange={handleLoginInputChange} placeholder="Enter Email" />
                                {errors.email === false ? (<div className="validationerror"> Please enter email </div>) : ('')}
                                <label>Email</label>
                            </div>
                            <div className="form-floating mb-4">
                                <input className={'form-control' + applyErrorClass('password')} name="password" type="password" value={loginValues.password} onChange={handleLoginInputChange} placeholder="Enter Password" />
                                {errors.password === false ? (<div className="validationerror"> Please enter password </div>) : ('')}
                                <label>Password</label>
                            </div>
                            <div className="modal-flex-item d-flex align-items-center justify-content-between mb-3">
                                <div className="modal-flex-first">
                                    <Link to={"/forget-password"} className="text-primary fw-medium">Forget Password?</Link>
                                </div>
                            </div>
                            <div className="form-group">
                                {btnSubmit === true ? (
                                    <button type="submit" className="btn btn-primary full-width font--bold btn-lg">Log In</button>
                                ) : (<button type="button" disabled className="btn btn-primary full-width font--bold btn-lg">Please wait...</button>)}
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <p>Don't have an account yet? Please <a onClick={handleRegisterShow} className="text-primary fw-medium ms-1">Sign Up</a></p>
                </Modal.Footer>
            </Modal>
            {/* End Modal */}
            <Modal show={registerShow} onHide={handleRegisterClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Register</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-login-form py-4 px-md-3 px-0">
                        {registerSuccess !== "" ? <div class="alert alert-info shadow-md"> <i class="fas fa-check-circle"></i> {registerSuccess} Please <a onClick={handleLoginShow}> Sign In </a> </div> : ""}
                        {registerError !== "" ? <div class="alert alert-danger shadow-md"> <i class="fas fa-check-circle"></i> {registerError} </div> : ""}
                        <form onSubmit={handleRegisterSubmit} autoComplete="off" noValidate>
                            <div className="form-floating mb-4">
                                <input className={'form-control' + applyErrorClass('name')} name="name" type="text" value={registerValues.name} onChange={handleRegisterInputChange} placeholder="Enter Name" />
                                {errors.name === false ? (<div className="validationerror"> Please enter name </div>) : ('')}
                                <label>Name</label>
                            </div>
                            <div className="form-floating mb-4">
                                <input className={'form-control' + applyErrorClass('email')} name="email" type="text" value={registerValues.email} onChange={handleRegisterInputChange} placeholder="Enter Email" />
                                {errors.email === false ? (<div className="validationerror"> Please enter email </div>) : ('')}
                                <label>Email</label>
                            </div>
                            <div className="form-floating mb-4">
                                <input className={'form-control' + applyErrorClass('phoneNumber')} name="phoneNumber" type="text" value={registerValues.phoneNumber} onChange={handleRegisterInputChange} placeholder="Enter Phone Number" />
                                {errors.phoneNumber === false ? (<div className="validationerror"> Please enter phoneNumber </div>) : ('')}
                                <label>Phone Number</label>
                            </div>
                            <div className="form-floating mb-4">
                                <input className={'form-control' + applyErrorClass('password')} name="password" type="password" value={registerValues.password} onChange={handleRegisterInputChange} placeholder="Enter Password (minimum 9 characters)" />
                                {errors.password === false ? (<div className="validationerror"> Please enter password </div>) : ('')}
                                <label>Password (minimum 9 characters)</label>
                            </div>
                            <div className="form-group">
                                {btnSubmit === true ? (
                                    <button type="submit" className="btn btn-primary full-width font--bold btn-lg">Register</button>
                                ) : (<button type="button" disabled className="btn btn-primary full-width font--bold btn-lg">Please wait...</button>)}
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <p>Already have an account? Please <a onClick={handleLoginShow} className="text-primary fw-medium ms-1">Sign In</a></p>
                </Modal.Footer>
            </Modal>
        </div>
        </div>
    );
}