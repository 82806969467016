import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import APIConfig, { APIACTIVATEURL } from "../Configuration/APIConfig";
import StarRating from "../Common/StarRating";
import PageLoader from "../Layout/PageLoader";
export default function Activities() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const [destination, setDestination] = useState('');
    const [destinationCode, setDestinationCode] = useState(0);
    const [departureDate, setDepartureDate] = useState(new Date());
    const [adults, setAdults] = useState(1);
    const [kids, setKids] = useState(0);
    const [searchData, setSearchData] = useState({});
    const [activities, setActivities] = useState({});
    const [tempPackages, setTempPackages] = useState({});
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const BindPageData = (searchQuery) => {
        setLoading(true);
        GetActivities(searchQuery);
        setSearchData(searchQuery);
        setAdults(searchQuery.adults);
        setKids(searchQuery.kids);
        setDestination(searchQuery.locationFrom)
        setDepartureDate(searchQuery.departureDate)
    }
    const GetActivities = (searchQuery) => {
        axios
            .post(APIConfig.APIACTIVATEURL + APIConfig.SEARCHACTIVITES, searchQuery, { ...headerconfig })
            .then((response) => {
                var responseData = response.data.response;
                if (responseData.succeeded) {
                    console.log(responseData)
                    setActivities(responseData.data);
                    setTempPackages(responseData.data);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            }).catch(function (error) {
                setLoading(false);
            });
    };
    useEffect(() => {
        if (state === null) {
            navigate('/search-activities')
        }
        else if (params === null) {
            navigate('/search-activities')
        }
        else if (state.searchQuery === null) {
            navigate('/search-activities')
        }
        else {
            if (localStorage.getItem("userId") !== null) {
                setUserId(localStorage.getItem("userId"))
            }
            BindPageData(state.searchQuery);
        }
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
            <div>
                <Header></Header>
                <div className="clearfix"></div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Activities</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="gray-simple">
                    <div className="container">
                        <div className="row justify-content-between gy-4 gx-xl-4 gx-lg-3 gx-md-3 gx-4">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-xl-4 col-lg-4 col-md-4">
                                        <h5 className="fw-bold fs-6 mb-lg-0 mb-3">Showing {activities.length} Search Results</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="row justify-content-center gy-4 gx-xl-4 gx-3">
                                    {activities.length > 0 && activities.map(activity =>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                            <div className="pop-touritem">
                                                <Link to={"/products/" + activity.attractionId + "/" + params.destinationCode + "/" + params.destination + "/" + params.adults + "/" + params.kids + "/" + params.travelDate} className="card rounded-3 m-0">
                                                    <div className="flight-thumb-wrapper p-2 pb-0">
                                                        <div className="popFlights-item-overHidden rounded-3">
                                                            <img src={activity.imageURL} className="img-fluid" alt={activity.productName} style={{ minWidth: "300px", minHeight: "300px" }} />
                                                        </div>
                                                    </div>
                                                    <div className="touritem-middle position-relative p-3">
                                                        <div className="touritem-flexxer">
                                                            <div className="explot">
                                                                <h4 className="city fs-title m-0 fw-bold">
                                                                    <span>{activity.attractionName}</span>
                                                                </h4>
                                                                <p>{activity.destinationName}, {activity.city}, {activity.state}</p>
                                                                <div className="rates">
                                                                    <div className="rat-reviews">
                                                                        <StarRating rating={activity.rating}></StarRating>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
}
        </div>
    )
}