import { Routes, Route } from 'react-router-dom';
import SearchFlight from './Flights/SearchFlight';
import SearchHotel from './Hotels/SearchHotel';
import SearchTour from './Tours/SearchTour';
import AboutUs from './Home/AboutUs';
import OneWay from './Flights/OneWay';
import OneWayFlight from './Flights/OneWayFlight';
import OneWayItinerary from './Flights/OneWayItinerary';
import RoundTrip from './Flights/RoundTrip';
import RoundTripFlight from './Flights/RoundTripFlight';
import RoundTripItinerary from './Flights/RoundTripItinerary';
import PrivacyPolicy from './Home/PrivacyPolicy';
import Terms from './Home/Terms';
import FAQ from './Home/FAQ';
import Contact from './Home/Contact';
import Profile from './User/Profile';
import Logout from './Authentication/Logout';
import HotelList from './Hotels/HotelList';
import HotelDetails from './Hotels/HotelDetails';
import HotelGuestDetail from './Hotels/HotelGuestDetail';
import HotelBookingSuccess from './Hotels/HotelBookingSucces';
import FlightBookings from './User/FlightBookings';
import HotelBookings from './User/HotelBookings';
import HotelBookingDetails from './User/HotelBookingDetails';
import ForgetPassword from './User/ForgetPassword';
import ResetPassword from './User/ResetPassword';
import Refund from './Home/Refund';
import OneWayReview from './Flights/OneWayReview';
import RoundTripReview from './Flights/RoundTripReview';
import HotelReview from './Hotels/HotelReview';
import Tour from './Tours/Tour';
import TourDetails from './Tours/TourDetails';
import SearchActivities from './Sightseeing/SearchActivities';
import Activities from './Sightseeing/Activities';
import SearchBus from './Buses/SearchBus';
import Buses from './Buses/Buses';
import Products from './Sightseeing/Products';
import Product from './Sightseeing/Product';
import SearchCar from './Cars/SearchCar';
import Cars from './Cars/Cars';
import FlightVoucher from './Flights/FlightVoucher';
import FlightInvoice from './Flights/FlightInvoice';
import FlightBookingVoucher from './User/FlightBookingVoucher';
import FlightBookingInvoice from './User/FlightBookingInvoice';
import ProductBooking from './Sightseeing/ProductBooking';
import ProductConfirmation from './Sightseeing/ProductConfirmation';
import CarPassengers from './Cars/CarPassengers';
import TransferBooking from './Cars/TransferBooking';
import ConfirmEmail from './User/ConfirmEmail';
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<SearchFlight />} />
        <Route path="/searchflights" element={<SearchFlight />} />
        <Route path="/oneway-flights/:adults?/:kids?/:infants?/:isDomestic?" element={<OneWay />} />
        <Route path="/oneway-flight/:adults?/:kids?/:infants??/:isDomestic?" element={<OneWayFlight />} />
        <Route path="/oneway-review/:adults?/:kids?/:infants??/:isDomestic?" element={<OneWayReview />} />
        <Route path="/oneway-itinerary" element={<OneWayItinerary />} />
        <Route path="/oneway-itinerary/:flightBookingId?" element={<OneWayItinerary />} />

        <Route path="/roundtrip-flights/:adults?/:kids?/:infants?/:isDomestic?" element={<RoundTrip />} />
        <Route path="/roundtrip-flight/:adults?/:kids?/:infants?/:isDomestic?" element={<RoundTripFlight />} />
        <Route path="/roundtrip-review/:adults?/:kids?/:infants?/:isDomestic?" element={<RoundTripReview />} />
        <Route path="/roundtrip-itinerary/:flightBookingId?" element={<RoundTripItinerary />} />
        <Route path="/flight-voucher/:flightBookingId?" element={<FlightVoucher />} />
        <Route path="/flight-invoice/:flightBookingId?" element={<FlightInvoice />} />

        <Route path="/searchhotels" element={<SearchHotel />} />
        <Route path="/hotelslist" element={<HotelList />} />
        <Route path="/hoteldetails" element={<HotelDetails />} />
        <Route path="/hotelreview" element={<HotelReview />} />
        <Route path="/hotelguests" element={<HotelGuestDetail />} />
        <Route path="/hotelbookingsuccess/:hotelBookingId?" element={<HotelBookingSuccess />} />

        <Route path="/search-activities" element={<SearchActivities />} />
        <Route path="/activities/:destinationCode/:destination/:adults?/:kids?/:travelDate" element={<Activities />} />
        <Route path="/products/:destinationCode/:destination/:adults?/:kids?/:travelDate" element={<Products />} />
        <Route path="/product/:productId/:destinationCode/:destination/:adults?/:kids?/:travelDate" element={<Product />} />
        <Route path="/productbooking/:productId/:destinationCode/:destination/:adults?/:kids?/:travelDate" element={<ProductBooking />} />
        <Route path="/productconfirmation/:productId/:destinationCode/:destination/:adults?/:kids?/:travelDate" element={<ProductConfirmation />} />

        <Route path="/search-bus" element={<SearchBus />} />
        <Route path="/buses/:locationFrom/:locationTo/:adults?/:travelDate" element={<Buses />} />

        <Route path="/searchcars" element={<SearchCar />} />
        <Route path="/cars/:adults?/:kids?/:travelDate" element={<Cars />} />
        <Route path="/carpassengers/:adults?/:kids?/:travelDate" element={<CarPassengers />} />
        <Route path="/transferbooking/:transferBookingId" element={<TransferBooking />} />

        <Route path="/searchtours" element={<SearchTour />} />
        <Route path="/tours" element={<Tour />} />
        <Route path="/tourdetails/:tourId?" element={<TourDetails />} />

        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/confirmemail" element={<ConfirmEmail />} />
        <Route path="/logout" element={<Logout />} />
        

        <Route path="/profile" element={<Profile />} />
        <Route path="/flightbookings" element={<FlightBookings />} />
        <Route path="/flight-bookings-details/:flightBookingId" element={<FlightBookingVoucher />} />
        <Route path="/flight-bookings-invoice/:flightBookingId" element={<FlightBookingInvoice />} />
        <Route path="/hotelbookings" element={<HotelBookings />} />
        <Route path="/hotel-bookings-details/:hotelBookingId" element={<HotelBookingDetails />} />
      </Routes>
    </div>
  );
}

export default App;
