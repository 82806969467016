import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import APIConfig, { APIACTIVATEURL } from "../Configuration/APIConfig";
import PageLoader from "../Layout/PageLoader";
import moment from "moment";
import countries from '../Data/Countries.json';
export default function ProductBooking() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [searchData, setSearchData] = useState({});
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const [product, setProduct] = useState({});
    const [adultTravellers, setAdultTravellers] = useState([]);
    const [kidTravellers, setKidTravellers] = useState([]);
    const [totalTravellers, setTotalTravellers] = useState(0);
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [paymentGatewayFee, setPaymentGatewayFee] = useState(0);
    const [paymentGateway, setPaymentGateway] = useState("RAZORPAY");
    const [paymentGateways, setPaymentGateways] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    function getformtravelData(tData) {
        var gender = "MALE";
        if (tData.title === "Mr") {
            gender = "MALE";
        }
        else if (tData.title === "Mrs") {
            gender = "FEMALE";
        }
        else if (tData.title === "Ms") {
            gender = "FEMALE";
        }
        else if (tData.title === "Master") {
            gender = "MALE";
        }
        const myObject = {
            travelerId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            firstName: tData.firstName,
            lastName: tData.lastName,
            title: tData.title,
            gender: gender,
            travelerType: tData.type,
            //dateOfBirth: moment(tData.dateOfBirth).format('YYYY-MM-DD'),
            dateOfBirth: params.isDomestic === 'INTERNATIONAL' ? moment(tData.dateOfBirth).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
            passportNo: tData.passportNo,
            passportNationality: tData.passportNationality,
            passbookIssueDate: params.isDomestic === 'INTERNATIONAL' ? moment(tData.passbookIssueDate).format('YYYY-MM-DD') : "",
            passbookExpireDate: params.isDomestic === 'INTERNATIONAL' ? moment(tData.passbookExpireDate).format('YYYY-MM-DD') : "",
        };
        return myObject;
    }
    const validate = (index, adata) => {
        let temp = {};
        temp.firstName = adata.firstName === "" ? false : true;
        temp.lastName = adata.lastName === "" ? false : true;
        if (params.isDomestic === 'INTERNATIONAL') {
            temp.dateOfBirth = adata.dateOfBirth === "" ? false : true;
            temp.passportNo = adata.passportNo === "" ? false : true;
            temp.passportNationality = adata.passportNationality === "" ? false : true;
            temp.passbookIssueDate = adata.passbookIssueDate === "" ? false : true;
            temp.passbookExpireDate = adata.passbookExpireDate === "" ? false : true;
        }
        temp.email = email === "" ? false : true;
        temp.mobile = mobile === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleAdultChange = (index, event) => {
        const values = [...adultTravellers];
        const updatedValue = event.target.name;
        values[index][updatedValue] = event.target.value;
        setAdultTravellers(values);
    };
    const handleKidsChange = (index, event) => {
        const values = [...kidTravellers];
        const updatedValue = event.target.name;
        values[index][updatedValue] = event.target.value;
        setKidTravellers(values);
    };
    const [errorAdult, setAdultError] = useState('');
    const [errorKid, setKidError] = useState('');
    const [errorInfant, setInfantError] = useState('');
    const validateAdultDate = (index, event) => {
        const inputDate = new Date(event.target.value);
        const year = inputDate.getFullYear().toString();
        const currentYear = new Date().getFullYear();
        const minYear = currentYear - 100;
        if (year.length > 4 && event.target.value) {
            setAdultError('The year must be 4 digits!');
            event.target.value = ''; // Clear the input value
        }
        if (year < minYear || year > currentYear) {
            setAdultError(`The year must be between ${minYear} and ${currentYear}!`);
            event.target.setCustomValidity(`The year must be between ${minYear} and ${currentYear}`);
            event.target.value = ''; // Clear the input value
        }
        else {
            const values = [...adultTravellers];
            const updatedValue = event.target.name;
            values[index][updatedValue] = event.target.value;
            setAdultTravellers(values);
            setAdultError('');
        }
    };
    const validateKidsDate = (index, event) => {
        const inputDate = new Date(event.target.value);
        const year = inputDate.getFullYear().toString();
        const currentYear = new Date().getFullYear();
        const minYear = currentYear - 12;
        if (year.length > 4 && event.target.value) {
            setKidError('The year must be 4 digits!');
            event.target.value = ''; // Clear the input value
        }
        if (year < minYear || year > currentYear) {
            setKidError(`The year must be between ${minYear} and ${currentYear}!`);
            event.target.setCustomValidity(`The year must be between ${minYear} and ${currentYear}`);
            event.target.value = ''; // Clear the input value
        }
        else {
            const values = [...kidTravellers];
            const updatedValue = event.target.name;
            values[index][updatedValue] = event.target.value;
            setKidTravellers(values);
            setKidError('');
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        var temptravellerinfos = [];
        for (var i = 0; i < adultTravellers.length; i++) {
            if (validate(i, adultTravellers[i])) {
                var tempData = getformtravelData(adultTravellers[i]);
                temptravellerinfos = [...temptravellerinfos, tempData];
            }
        }
        for (var i = 0; i < kidTravellers.length; i++) {
            if (validate(i, kidTravellers[i])) {
                var tempData = getformtravelData(kidTravellers[i]);
                temptravellerinfos = [...temptravellerinfos, tempData];
            }
        }
        if (errorAdult !== "" || errorKid !== "" || errorInfant !== "") {
            alert("Please check the errors");
        }
        else if (temptravellerinfos.length === totalTravellers) {
            searchData.travellerInfos = temptravellerinfos;
            displayRazorpay(searchData);
        }
        else {
            setLoading(false);
            alert("Please enter travelers data");
        }
    };
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    async function displayRazorpay(searchData) {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        const result = await axios.post(
            APIConfig.APIACTIVATEURL + APIConfig.PAYMENTREQUESTORDER + "?Amount=" + Math.round(parseFloat(searchData.customerPrice) + parseFloat(paymentGatewayFee)) + "&PayRequest=" + paymentGateway
        );

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }
        // Getting the order details back
        if (result.data.response.succeeded) {
            const { amount, id: order_id, currency, razorpayKey, name } = result.data.response.data;
            const options = {
                key: razorpayKey, // Enter the Key ID generated from the Dashboard
                amount: amount,
                currency: currency,
                name: name,
                description: "SightSeeing Booking Fee",
                order_id: order_id,
                handler: async function (response) {
                    InsertBooking(razorpayKey, searchData);
                },
                prefill: {
                    name: searchData.travellerInfos[0].firstName + " " + searchData.travellerInfos[0].lastName,
                    email: APIConfig.COMPANYEMAIL,
                    contact: APIConfig.COMAPNYPHONE,
                },
                notes: {
                    address: APIConfig.COMPANYCITY,
                },
                theme: {
                    color: "#61dafb",
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }
        else {
            alert("Payment failed. Please try again");
        }
    }
    const InsertBooking = (paymentGatewayNo, searchData) => {
        searchData.transactionNo = paymentGatewayNo;
        searchData.paymentStatus = "SUCCESS";
        searchData.paymentGatewayNo = paymentGatewayNo;
        searchData.totalPrice = parseFloat(searchData.customerPrice) + parseFloat(paymentGatewayFee);
        searchData.paymentGatewayFee = paymentGatewayFee;
        searchData.gatewayAmount = searchData.customerPrice;
        searchData.walletAmount = 0;
        searchData.creditAmount = 0;
        searchData.markup = 0;
        searchData.email = email;
        searchData.mobile = mobile;
        searchData.customerPrice = parseFloat(searchData.customerPrice) + parseFloat(paymentGatewayFee);
        searchData.paymentGateway = paymentGateway;
        axios.post(APIConfig.APIACTIVATEURL + APIConfig.BOOKSIGHTSEEING, searchData, { ...headerconfig })
            .then((response) => {
                var responseData = response.data.response;
                console.log(responseData)
                //searchData = responseData.data;
                    if (searchData.loginResponse !== null) {
                        //localStorage.setItem('userId', searchData.loginResponse.userId);
                        //localStorage.setItem('name', searchData.loginResponse.name);
                        //localStorage.setItem('roleName', searchData.loginResponse.role);
                        //localStorage.setItem('userToken', searchData.loginResponse.token);
                        //localStorage.setItem('tokenexpiration', searchData.loginResponse.expiration);
                    }
                setLoading(false)
                navigate("/productconfirmation/" + params.productId + "/" + params.destinationCode + "/" + params.destination + "/" + params.adults + "/" + params.kids + "/" + params.travelDate, { state: { searchData }});
            });
    }
    const GetUserData = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETUSERBYID + "?Id=" + localStorage.getItem('userId'), { ...headerconfig })
            .then((response) => {
                if (response.data.response.succeeded) {
                    setEmail(response.data.response.data.email)
                    setMobile(response.data.response.data.phoneNumber)
                }
            });
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }
    const handleMobileChange = (e) => {
        setMobile(e.target.value)
    }
    const [minDate, setMinDate] = useState('');
    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        setMinDate(today);
    }, []);
    useEffect(() => {
        if (state === null) {
            navigate('/search-activities')
        }
        else if (params === null) {
            navigate('/search-activities')
        }
        else if (state.searchQuery === null) {
            navigate('/search-activities')
        }
        else {
            if (localStorage.getItem("userId") !== null) {
                setUserId(localStorage.getItem("userId"))
            }
            setLoading(false);
            GetUserData();
            setSearchData(state.searchQuery);
            setTotalTravellers(parseInt(params.adults) + parseInt(params.kids))
            const avalues = [...adultTravellers];
            if (adultTravellers.length < params.adults) {
                for (var i = 0; i < params.adults; i++) {
                    avalues.push({ title: "Mr", firstName: "", lastName: "", type: "ADULT", dateOfBirth: "", passportNo: "", passportNationality: "IN", passbookIssueDate: "", passbookExpireDate: "" });
                    setAdultTravellers(avalues);
                }
            }
            //setTotalKids(params.kids)
            const kvalues = [...kidTravellers];
            if (kidTravellers.length < params.kids) {
                for (var i = 0; i < params.kids; i++) {
                    kvalues.push({ title: "Master", firstName: "", lastName: "", type: "CHILD", dateOfBirth: "", passportNo: "", passportNationality: "IN", passbookIssueDate: "", passbookExpireDate: "" });
                    setKidTravellers(kvalues);
                }
            }
        }
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <div className="py-2 gray-simple position-relative">
                        <div className="container">
                            {/* Search Form */}
                            <div className="row justify-content-center align-items-center">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item"><Link to={"/"} className="text-primary">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Activities</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="gray-simple">
                        <div classname="container">
                            <div classname="row justify-content-between gy-4 gx-xl-4 gx-lg-3 gx-md-3 gx-4">
                                <div id="content">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="bg-white shadow-md rounded p-3 p-sm-4 confirm-details">
                                                    {/* Known For end */}
                                                    <div className="col-xl-12 col-lg-12 col-md-12">
                                                        {/* Overview */}
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h6 className="fw-semibold mb-0">Traveler Details</h6>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="bg-success bg-opacity-10 rounded-2 p-3 mb-3">
                                                                    <p className="h6 text-md mb-0"><span className="badge bg-success me-2">New</span>Please enter your name as per your passport ID</p>
                                                                </div>
                                                                <div className="full-width d-flex flex-column mb-4 position-relative">
                                                                    <div className="row align-items-stat">
                                                                        {adultTravellers.length > 0 && adultTravellers.map((adult, index) =>
                                                                            <div>
                                                                                <p className="fw-600">Adult {parseInt(index + 1)}</p>
                                                                                <div className="row g-3 mb-4">
                                                                                    <div className="col-sm-2">
                                                                                        <labe>Title</labe>
                                                                                        <select className="form-control" name="title" required value={adult.title} onChange={(event) => handleAdultChange(index, event)}>
                                                                                            <option value="Mr">Mr</option>
                                                                                            <option value="Mrs">Mrs</option>
                                                                                            <option value="Ms">Ms</option>
                                                                                            <option value="Master">Master</option>
                                                                                        </select>
                                                                                        {errors.title === false ? (<div className="validationerror">Please select title </div>) : ('')}
                                                                                    </div>
                                                                                    <div className="col-sm-5">
                                                                                        <labe>Givenname</labe>
                                                                                        <input className="form-control" name="firstName" value={adult.firstName} onChange={(event) => handleAdultChange(index, event)} required placeholder="Enter First Name" type="text" />
                                                                                        {errors.firstName === false ? (<div className="validationerror">Please enter firstName </div>) : ('')}
                                                                                    </div>
                                                                                    <div className="col-sm-5">
                                                                                        <labe>Surname</labe>
                                                                                        <input className="form-control" name="lastName" value={adult.lastName} onChange={(event) => handleAdultChange(index, event)} required placeholder="Enter Surname" type="text" />
                                                                                        {errors.lastName === false ? (<div className="validationerror">Please enter surname </div>) : ('')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {kidTravellers.length > 0 && kidTravellers.map((adult, index) =>
                                                                            <div>
                                                                                <p className="fw-600">CHILD {parseInt(index + 1)}</p>
                                                                                <div className="row g-3 mb-3">
                                                                                    <div className="col-sm-2">
                                                                                        <label>Title</label>
                                                                                        <select className="form-control" name="title" required value={adult.title} onChange={(event) => handleKidsChange(index, event)}>
                                                                                            <option value="Ms">Ms</option>
                                                                                            <option value="Master">Master</option>
                                                                                        </select>
                                                                                        {errors.title === false ? (<div className="validationerror">Please select title </div>) : ('')}
                                                                                    </div>
                                                                                    <div className="col-sm-5">
                                                                                        <label>Givenname</label>
                                                                                        <input className="form-control" name="firstName" value={adult.firstName} onChange={(event) => handleKidsChange(index, event)} required placeholder="Enter Givenname" type="text" />
                                                                                        {errors.firstName === false ? (<div className="validationerror">Please enter givenname </div>) : ('')}
                                                                                    </div>
                                                                                    <div className="col-sm-5">
                                                                                        <label>Surname</label>
                                                                                        <input className="form-control" name="lastName" value={adult.lastName} onChange={(event) => handleKidsChange(index, event)} required placeholder="Enter Surname" type="text" />
                                                                                        {errors.lastName === false ? (<div className="validationerror">Please enter surname </div>) : ('')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="full-width d-flex flex-column mb-2 position-relative">
                                                                    <div className="row align-items-stat">
                                                                        <div className="col-xl-12 col-lg-12 col-md-12 mb-2">
                                                                            <h5>Personal Information</h5>
                                                                        </div>
                                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Email Address</label>
                                                                                <input type="text" maxLength={45} value={email} name="email" onChange={handleEmailChange} className="form-control" placeholder="Email Here" />
                                                                                {errors.email === false ? (<div className="validationerror">Please enter email </div>) : ('')}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                                                            <div className="form-group">
                                                                                <label className="form-label">Mobile number</label>
                                                                                <input type="number" maxLength={12} value={mobile} name="mobile" onChange={handleMobileChange} className="form-control" placeholder="Mobile Number" />
                                                                                {errors.mobile === false ? (<div className="validationerror">Please enter mobile </div>) : ('')}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <aside className="col-lg-4 mt-4 mt-lg-0">
                                                <div className="bg-white shadow-md rounded p-3 mb-4">
                                                    <div className="position-relative ps-4">
                                                        <div className="location-brief-line"> <em className="location-brief-pickup" /> <em className="location-brief-dropoff" /> </div>
                                                        <h6 className="text-4 mb-2">Name</h6>
                                                        <ul className="list-unstyled">
                                                            <li className="mb-1">{searchData.productName}</li>
                                                        </ul>
                                                    </div>
                                                    <div className="position-relative ps-4">
                                                        <div className="location-brief-line"> <em className="location-brief-pickup" /> <em className="location-brief-dropoff" /> </div>
                                                        <h6 className="text-4 mb-2">Destination</h6>
                                                        <ul className="list-unstyled">
                                                            <li className="mb-1">{searchData.city}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="bg-white shadow-md rounded p-3 sticky-top">
                                                    <h6 className="text-5 mb-3">Price Summary</h6>
                                                    <hr className="mx-n3" />
                                                    <ul className="list-unstyled">
                                                        <li className="mb-2">Price <span className="float-end text-5 fw-500 text-dark">{searchData.currencyCode} {searchData.price}</span><br /></li>
                                                    </ul>
                                                    <hr />
                                                    <div className="text-dark text-4 fw-500 my-4"> Total Amount<span className="float-end text-9">{searchData.currencyCode} {searchData.totalPrice}</span></div>
                                                    <hr />
                                                    <div className="d-grid">
                                                        <button className="btn btn-primary" type="submit" onClick={handleSubmit}>Book Now</button>
                                                    </div>
                                                    <p className="text-danger text-center mt-3 mb-1"><i className="far fa-clock" /> Last Booked - 6 hours ago</p>
                                                </div>
                                                {/* Price Summary End */}
                                            </aside>
                                            {/* Side Panel End */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div></section>
                    <Footer></Footer>
                </div >
            }
        </div >
    )
}