import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import APIConfig, { APIACTIVATEURL } from "../Configuration/APIConfig";
export default function Buses() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const [destination, setDestination] = useState('');
    const [destinationCode, setDestinationCode] = useState(0);
    const [departureDate, setDepartureDate] = useState(new Date());
    const [adults, setAdults] = useState(1);
    const [kids, setKids] = useState(0);
    const [searchData, setSearchData] = useState({});
    const [activities, setActivities] = useState({});
    const [tempPackages, setTempPackages] = useState({});
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const BindPageData = (searchQuery) => {
        setLoading(true);
        GetActivities(searchQuery);
        setSearchData(searchQuery);
        setAdults(searchQuery.adults);
        setKids(searchQuery.kids);
        setDestination(searchQuery.locationFrom)
        setDepartureDate(searchQuery.departureDate)
    }
    const GetActivities = (searchQuery) => {
        axios
            .post(APIConfig.APIACTIVATEURL + APIConfig.SEARCHACTIVITES, searchQuery, { ...headerconfig })
            .then((response) => {
                var responseData = response.data.response;
                if (responseData.succeeded) {
                    console.log(responseData)
                    setActivities(responseData.data);
                    setTempPackages(responseData.data);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            }).catch(function (error) {
                setLoading(false);
            });
    };
    useEffect(() => {
        if (state === null) {
            navigate('/search-bus')
        }
        else if (params === null) {
            navigate('/search-bus')
        }
        else if (state.searchQuery === null) {
            navigate('/search-bus')
        }
        else {
            if (localStorage.getItem("userId") !== null) {
                setUserId(localStorage.getItem("userId"))
            }
            //BindPageData(state.searchQuery);
        }
    }, []);
    return (
        <div id="main-wrapper">
            <div>
                <Header></Header>
                <div className="clearfix"></div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Buses</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="gray-simple">
                    <div id="content">
                        <section className="container">
                            <div className="row">
                                <aside className="col-md-3">
                                    <div className="bg-white shadow-md rounded p-3">
                                        <h3 className="text-5">Filter</h3>
                                        <hr className="mx-n3" />
                                        <div className="accordion accordion-flush style-2 mt-n3" id="toggleAlternate">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="departure">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#toggleDeparture" aria-expanded="true" aria-controls="togglePrice">Departure Time</button>
                                                </h2>
                                                <div id="toggleDeparture" className="accordion-collapse collapse show" aria-labelledby="departure">
                                                    <div className="accordion-body">
                                                        <p><span className="slider-time-departure">00:00</span> - <span className="slider-time-departure">23:59</span></p>
                                                        <div id="slider-range-departure" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="price">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#togglePrice" aria-expanded="true" aria-controls="togglePrice">Price</button>
                                                </h2>
                                                <div id="togglePrice" className="accordion-collapse collapse show" aria-labelledby="price">
                                                    <div className="accordion-body">
                                                        <p>
                                                            <input id="amount" type="text" readOnly className="form-control border-0 bg-transparent p-0" />
                                                        </p>
                                                        <div id="slider-range" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="busType">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#togglebusType" aria-expanded="true" aria-controls="togglebusType">Bus Type</button>
                                                </h2>
                                                <div id="togglebusType" className="accordion-collapse collapse show" aria-labelledby="busType">
                                                    <div className="accordion-body">
                                                        <div className="form-check">
                                                            <input type="checkbox" id="sleeper" name="busType" className="form-check-input" />
                                                            <label className="form-check-label" htmlFor="sleeper">Sleeper</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="checkbox" id="seater" name="busType" className="form-check-input" />
                                                            <label className="form-check-label" htmlFor="seater">Seater</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="checkbox" id="semiseater" name="busType" className="form-check-input" />
                                                            <label className="form-check-label" htmlFor="semiseater">Semi-Seater</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="busOperators">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#togglebusOperators" aria-expanded="true" aria-controls="togglebusOperators">Bus Operators</button>
                                                </h2>
                                                <div id="togglebusOperators" className="accordion-collapse collapse show" aria-labelledby="busOperators">
                                                    <div className="accordion-body pb-0">
                                                        <div className="form-check">
                                                            <input type="checkbox" id="akTour" name="busOperators" className="form-check-input" />
                                                            <label className="form-check-label" htmlFor="akTour">AK Tour &amp; Travels</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="checkbox" id="vikasTravels" name="busOperators" className="form-check-input" />
                                                            <label className="form-check-label" htmlFor="vikasTravels">Vikas Travels</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="checkbox" id="gujaratTravels" name="busOperators" className="form-check-input" />
                                                            <label className="form-check-label" htmlFor="gujaratTravels">Gujarat Travels</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="checkbox" id="shrinathTravel" name="busOperators" className="form-check-input" />
                                                            <label className="form-check-label" htmlFor="shrinathTravel">Shrinath Travel Agency</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input type="checkbox" id="indianTravels" name="busOperators" className="form-check-input" />
                                                            <label className="form-check-label" htmlFor="indianTravels">Indian Travels Agency</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                                {/* Side Panel end */}
                                <div className="col-md-9 mt-4 mt-md-0">
                                    <div className="bg-white shadow-md rounded py-4">
                                        <div className="mx-3 mb-3 text-center">
                                            <h2 className="text-6 mb-4">{params.locationFrom} <small className="mx-2">to</small>{params.locationTo} </h2>
                                        </div>
                                        <div className="text-1 bg-light-3 border border-end-0 border-start-0 py-2 px-3">
                                            <div className="row">
                                                <div className="col col-sm-3"><span className="d-none d-sm-block">Operators</span></div>
                                                <div className="col col-sm-2 text-center">Departure</div>
                                                <div className="col-sm-2 text-center d-none d-sm-block">Duration</div>
                                                <div className="col col-sm-2 text-center">Arrival</div>
                                                <div className="col col-sm-3 text-center d-none d-sm-block">Price</div>
                                            </div>
                                        </div>
                                        <div className="bus-list">
                                            <div className="bus-item">
                                                <div className="row align-items-sm-center flex-row py-4 px-3">
                                                    <div className="col col-sm-3"> <span className="text-3 text-dark operator-name">AK Tour &amp; Travels</span> <span className="text-1 text-muted d-block">AC Sleeper</span> </div>
                                                    <div className="col col-sm-2 text-center time-info"> <span className="text-4 text-dark">12:00</span> <small className="text-muted d-block">Mumbai</small> </div>
                                                    <div className="col col-sm-2 text-center d-none d-sm-block time-info"> <span className="text-3 duration">06h 32m</span> <small className="text-muted d-block">12 Stops</small> </div>
                                                    <div className="col col-sm-2 text-center time-info"> <span className="text-4 text-dark">05:15</span> <small className="text-muted d-block">Surat</small> </div>
                                                    <div className="col-12 col-sm-3 align-self-center text-end text-sm-center mt-2 mt-sm-0">
                                                        <div className="d-inline-block d-sm-block text-dark text-5 price mb-sm-1">$250</div>
                                                        <a href="#" className="btn btn-sm btn-outline-primary shadow-none" data-bs-toggle="modal" data-bs-target="#select-busseats"><i className="fas fa-ellipsis-h d-none d-sm-block d-lg-none" data-bs-toggle="tooltip" title="Select Seats" /> <span className="d-block d-sm-none d-lg-block">Select Seats</span></a> </div>
                                                </div>
                                            </div>
                                            <div className="bus-item">
                                                <div className="row align-items-sm-center flex-row py-4 px-3">
                                                    <div className="col col-sm-3"> <span className="text-3 text-dark operator-name">Gujarat Travels</span> <span className="text-1 text-muted d-block">AC Sleeper</span> </div>
                                                    <div className="col col-sm-2 text-center time-info"> <span className="text-4 text-dark">12:00</span> <small className="text-muted d-block">Mumbai</small> </div>
                                                    <div className="col col-sm-2 text-center d-none d-sm-block time-info"> <span className="text-3 duration">06h 32m</span> <small className="text-muted d-block">12 Stops</small> </div>
                                                    <div className="col col-sm-2 text-center time-info"> <span className="text-4 text-dark">05:15</span> <small className="text-muted d-block">Surat</small> </div>
                                                    <div className="col-12 col-sm-3 align-self-center text-end text-sm-center mt-2 mt-sm-0">
                                                        <div className="d-inline-block d-sm-block text-dark text-5 price mb-sm-1">$195</div>
                                                        <a href="#" className="btn btn-sm btn-outline-primary shadow-none" data-bs-toggle="modal" data-bs-target="#select-busseats"><i className="fas fa-ellipsis-h d-none d-sm-block d-lg-none" data-bs-toggle="tooltip" title="Select Seats" /> <span className="d-block d-sm-none d-lg-block">Select Seats</span></a> </div>
                                                </div>
                                            </div>
                                            <div className="bus-item">
                                                <div className="row align-items-sm-center flex-row py-4 px-3">
                                                    <div className="col col-sm-3"> <span className="text-3 text-dark operator-name">Shrinath Travels</span> <span className="text-1 text-muted d-block">AC Sleeper</span> </div>
                                                    <div className="col col-sm-2 text-center time-info"> <span className="text-4 text-dark">12:00</span> <small className="text-muted d-block">Mumbai</small> </div>
                                                    <div className="col col-sm-2 text-center d-none d-sm-block time-info"> <span className="text-3 duration">06h 32m</span> <small className="text-muted d-block">12 Stops</small> </div>
                                                    <div className="col col-sm-2 text-center time-info"> <span className="text-4 text-dark">05:15</span> <small className="text-muted d-block">Surat</small> </div>
                                                    <div className="col-12 col-sm-3 align-self-center text-end text-sm-center mt-2 mt-sm-0">
                                                        <div className="d-inline-block d-sm-block text-dark text-5 price mb-sm-1">$221</div>
                                                        <a href="#" className="btn btn-sm btn-outline-primary shadow-none" data-bs-toggle="modal" data-bs-target="#select-busseats"><i className="fas fa-ellipsis-h d-none d-sm-block d-lg-none" data-bs-toggle="tooltip" title="Select Seats" /> <span className="d-block d-sm-none d-lg-block">Select Seats</span></a> </div>
                                                </div>
                                            </div>
                                            <div className="bus-item">
                                                <div className="row align-items-sm-center flex-row py-4 px-3">
                                                    <div className="col col-sm-3"> <span className="text-3 text-dark operator-name">Vikas Travels</span> <span className="text-1 text-muted d-block">AC Sleeper</span> </div>
                                                    <div className="col col-sm-2 text-center time-info"> <span className="text-4 text-dark">12:00</span> <small className="text-muted d-block">Mumbai</small> </div>
                                                    <div className="col col-sm-2 text-center d-none d-sm-block time-info"> <span className="text-3 duration">06h 32m</span> <small className="text-muted d-block">12 Stops</small> </div>
                                                    <div className="col col-sm-2 text-center time-info"> <span className="text-4 text-dark">05:15</span> <small className="text-muted d-block">Surat</small> </div>
                                                    <div className="col-12 col-sm-3 align-self-center text-end text-sm-center mt-2 mt-sm-0">
                                                        <div className="d-inline-block d-sm-block text-dark text-5 price mb-sm-1">$245</div>
                                                        <a href="#" className="btn btn-sm btn-outline-primary shadow-none" data-bs-toggle="modal" data-bs-target="#select-busseats"><i className="fas fa-ellipsis-h d-none d-sm-block d-lg-none" data-bs-toggle="tooltip" title="Select Seats" /> <span className="d-block d-sm-none d-lg-block">Select Seats</span></a> </div>
                                                </div>
                                            </div>
                                            <div className="bus-item">
                                                <div className="row align-items-sm-center flex-row py-4 px-3">
                                                    <div className="col col-sm-3"> <span className="text-3 text-dark operator-name">VTK Travels</span> <span className="text-1 text-muted d-block">AC Sleeper</span> </div>
                                                    <div className="col col-sm-2 text-center time-info"> <span className="text-4 text-dark">12:00</span> <small className="text-muted d-block">Mumbai</small> </div>
                                                    <div className="col col-sm-2 text-center d-none d-sm-block time-info"> <span className="text-3 duration">06h 32m</span> <small className="text-muted d-block">12 Stops</small> </div>
                                                    <div className="col col-sm-2 text-center time-info"> <span className="text-4 text-dark">05:15</span> <small className="text-muted d-block">Surat</small> </div>
                                                    <div className="col-12 col-sm-3 align-self-center text-end text-sm-center mt-2 mt-sm-0">
                                                        <div className="d-inline-block d-sm-block text-dark text-5 price mb-sm-1">$199</div>
                                                        <a href="#" className="btn btn-sm btn-outline-primary shadow-none" data-bs-toggle="modal" data-bs-target="#select-busseats"><i className="fas fa-ellipsis-h d-none d-sm-block d-lg-none" data-bs-toggle="tooltip" title="Select Seats" /> <span className="d-block d-sm-none d-lg-block">Select Seats</span></a> </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Pagination
        ============================================= */}
                                        <ul className="pagination justify-content-center mt-4 mb-0">
                                            <li className="page-item disabled"> <a className="page-link" href="#" tabIndex={-1}><i className="fas fa-angle-left" /></a> </li>
                                            <li className="page-item active"> <a className="page-link" href="#">1</a> </li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item"> <a className="page-link" href="#"><i className="fas fa-angle-right" /></a> </li>
                                        </ul>
                                        {/* Pagination end */}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <div id="select-busseats" className="modal fade" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Bus Booking Details</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="bus-details">
                                    <div className="row align-items-sm-center flex-row mb-4">
                                        <div className="col col-sm-3"> <span className="text-4 text-dark operator-name">AK Tour &amp; Travels</span> <span className="text-muted d-block">AC Sleeper</span> </div>
                                        <div className="col col-sm-3 text-center time-info"> <span className="text-5 text-dark">12:00</span> <small className="text-muted d-block">Mumbai</small> </div>
                                        <div className="col col-sm-3 text-center d-none d-sm-block time-info"> <span className="text-3 duration">06h 32m</span> <small className="text-muted d-block">12 Stops</small> </div>
                                        <div className="col col-sm-3 text-center time-info"> <span className="text-5 text-dark">05:15</span> <small className="text-muted d-block">Surat</small> </div>
                                    </div>
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item"> <a className="nav-link active" id="first-tab" data-bs-toggle="tab" href="#first" role="tab" aria-controls="first" aria-selected="true">Available Seats</a> </li>
                                        <li className="nav-item"> <a className="nav-link" id="second-tab" data-bs-toggle="tab" href="#second" role="tab" aria-controls="second" aria-selected="false">Cancellation Fee</a> </li>
                                    </ul>
                                    <div className="tab-content my-3" id="myTabContent">
                                        <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                                            <div className="row">
                                                <div className="col-12 col-lg-6 text-center">
                                                    <p className="text-muted text-1">Click on Seat to select/ deselect</p>
                                                    <div id="seat-map">
                                                        <div className="front-indicator">Front</div>
                                                    </div>
                                                    <div id="legend" />
                                                </div>
                                                <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                                                    <div className="booking-details">
                                                        <h2 className="text-5">Booking Details</h2>
                                                        <p>Selected Seats (<span id="counter">0</span>):</p>
                                                        <ul id="selected-seats">
                                                        </ul>
                                                        <div className="d-flex bg-light-4 px-3 py-2 mb-3">Total Fare: <span className="text-dark text-5 fw-600 ms-2">$<span id="total">0</span></span></div>
                                                        <div className="d-grid">
                                                            <button className="btn btn-primary">Continue</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="second" role="tabpanel" aria-labelledby="second-tab">
                                            <table className="table table-hover table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Hours before Departure</th>
                                                        <th className="text-center">Refund Percentage</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Before 0 Hrs.</td>
                                                        <td className="text-center">0%</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Before 24 Hrs.</td>
                                                        <td className="text-center">30%</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Before 48 Hrs.</td>
                                                        <td className="text-center">60%</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Before 60 Hrs.</td>
                                                        <td className="text-center">75%</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Above 60 Hrs. </td>
                                                        <td className="text-center">80%</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p className="fw-bold">Terms &amp; Conditions</p>
                                            <ul>
                                                <li>The penalty is subject to 24 hrs before departure. No Changes are allowed after that.</li>
                                                <li>The charges are per seat.</li>
                                                <li>Partial cancellation is not allowed on tickets booked under special discounted fares.</li>
                                                <li>In case of no-show or ticket not cancelled within the stipulated time, only statutory taxes are refundable subject to Service Fee.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer></Footer>
            </div>
        </div>
    )
}