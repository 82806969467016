import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import APIConfig, { APIACTIVATEURL } from "../Configuration/APIConfig";
import transfersdata from '../Data/TransferCars.json';
import moment from "moment";
import PageLoader from "../Layout/PageLoader";
export default function Cars() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const [travelSearchRequest, setTravelSearchRequest] = useState({});
    const [vehicles, setVehicles] = useState([]);
    const [tempPackages, setTempPackages] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            'Content-Type': 'application/json'
        }
    }
    const BindPageData = (searchQuery) => {
        setLoading(true);
        GetTransfers(searchQuery);
    }
    const GetTransfers = (searchQuery) => {
        axios.post(APIConfig.APIACTIVATEURL + APIConfig.SEARCHTRANSFER, searchQuery, { ...headerconfig })
            .then((response) => {
                var responseData = response.data.response;
                console.log(responseData.data)
                if (responseData.succeeded) {
                    if (responseData.data.vehicleData != null) {
                        setVehicles(responseData.data.vehicleData);
                        setTempPackages(responseData.data.vehicleData);
                        setLoading(false);
                    }
                }
            });
    };
    const handleCar = (e, vehicleData) => {
        e.preventDefault();
        navigate('/carpassengers/' + params.adults + "/" + params.kids + "/" + moment(params.travelDate).format('YYYY-MM-DD'), { state: { travelSearchRequest, vehicleData } });
    }
    useEffect(() => {
        if (state === null) {
            navigate('/searchcars')
        }
        else if (params === null) {
            navigate('/searchcars')
        }
        else if (state.searchQuery === null) {
            navigate('/searchcars')
        }
        else {
            if (localStorage.getItem("userId") !== null) {
                setUserId(localStorage.getItem("userId"))
            }
            setTravelSearchRequest(state.searchQuery)
            BindPageData(state.searchQuery);
            //var data = transfersdata.response.data;
            //console.log(data.vehicleData)
            //setVehicles(data.vehicleData);
            //setTempPackages(data.vehicleData);
            //setLoading(false);
        }
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <div className="py-2 gray-simple position-relative">
                        <div className="container">
                            {/* Search Form */}
                            <div className="row justify-content-center align-items-center">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Cars</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="gray-simple">
                        <div id="content">
                            <section className="container">
                                <div className="row">
                                    {/* Side Panel
  ============================================= */}
                                    <aside className="col-lg-3">
                                        <div className="bg-white shadow-md rounded p-3">
                                            <h3 className="text-5">Filter</h3>
                                            <hr className="mx-n3" />
                                            <div className="accordion accordion-flush style-2 mt-n3" id="toggleAlternate">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="carType">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#togglecarType" aria-expanded="true" aria-controls="togglecarType">Car Type</button>
                                                    </h2>
                                                    <div id="togglecarType" className="accordion-collapse collapse show" aria-labelledby="carType">
                                                        <div className="accordion-body">
                                                            <div className="form-check">
                                                                <input type="checkbox" id="economy" name="carType" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="economy">Economy <small className="text-muted float-end">99</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="compact" name="carType" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="compact">Compact <small className="text-muted float-end">76</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="midsize" name="carType" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="midsize">Midsize <small className="text-muted float-end">31</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="standard" name="carType" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="standard">Standard <small className="text-muted float-end">12</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="fullsize" name="carType" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="fullsize">Full-size <small className="text-muted float-end">5</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="minivan" name="carType" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="minivan">Minivan <small className="text-muted float-end">27</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="suv" name="carType" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="suv">SUV <small className="text-muted float-end">66</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="van" name="carType" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="van">Van <small className="text-muted float-end">18</small></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="passengers">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#togglepassengers" aria-expanded="true" aria-controls="togglepassengers">Passengers</button>
                                                    </h2>
                                                    <div id="togglepassengers" className="accordion-collapse collapse show" aria-labelledby="passengers">
                                                        <div className="accordion-body">
                                                            <div className="form-check">
                                                                <input type="checkbox" id="1to2" name="passengers" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="1to2">1 to 2 passengers<small className="text-muted float-end">85</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="3to5" name="passengers" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="3to5">3 to 5 passengers<small className="text-muted float-end">62</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="6orMore" name="passengers" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="6orMore">6 or more<small className="text-muted float-end">35</small></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="bags">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#toggleBags" aria-expanded="true" aria-controls="toggleBags">Bags</button>
                                                    </h2>
                                                    <div id="toggleBags" className="accordion-collapse collapse show" aria-labelledby="bags">
                                                        <div className="accordion-body">
                                                            <div className="form-check">
                                                                <input type="checkbox" id="1to2" name="bags" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="1to2">1 to 2 bags<small className="text-muted float-end">106</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="3to5" name="bags" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="3to5">3 to 4 bags<small className="text-muted float-end">85</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="6orMore" name="bags" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="6orMore">5 or more<small className="text-muted float-end">15</small></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="transmission">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#toggletransmission" aria-expanded="true" aria-controls="toggletransmission">Transmission</button>
                                                    </h2>
                                                    <div id="toggletransmission" className="accordion-collapse collapse show" aria-labelledby="bags">
                                                        <div className="accordion-body">
                                                            <div className="form-check">
                                                                <input type="checkbox" id="paynow" name="transmission" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="paynow">Automatic<small className="text-muted float-end">58</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="payatcounter" name="transmission" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="payatcounter">Manual<small className="text-muted float-end">168</small></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="userReview">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#toggleuserReview" aria-expanded="true" aria-controls="toggleuserReview">User Review</button>
                                                    </h2>
                                                    <div id="toggleuserReview" className="accordion-collapse collapse show" aria-labelledby="userReview">
                                                        <div className="accordion-body">
                                                            <div className="form-check">
                                                                <input type="checkbox" id="excellent" name="userReview" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="excellent">Excellent <small className="text-muted float-end">499</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="good" name="userReview" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="good">Good <small className="text-muted float-end">310</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="fair" name="userReview" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="fair">Fair <small className="text-muted float-end">225</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="poor" name="userReview" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="poor">Poor <small className="text-muted float-end">110</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="bad" name="userReview" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="bad">Bad <small className="text-muted float-end">44</small></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="price">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#togglePrice" aria-expanded="true" aria-controls="togglePrice">Price</button>
                                                    </h2>
                                                    <div id="togglePrice" className="accordion-collapse collapse show" aria-labelledby="price">
                                                        <div className="accordion-body">
                                                            <p>
                                                                <input id="amount" type="text" readOnly className="form-control border-0 bg-transparent p-0" />
                                                            </p>
                                                            <div id="slider-range" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="paymenttype">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#togglePaymenttype" aria-expanded="true" aria-controls="togglePaymenttype">Payment type</button>
                                                    </h2>
                                                    <div id="togglePaymenttype" className="accordion-collapse collapse show" aria-labelledby="bags">
                                                        <div className="accordion-body pb-0">
                                                            <div className="form-check">
                                                                <input type="checkbox" id="paynow" name="paymenttype" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="paynow">Pay now<small className="text-muted float-end">58</small></label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input type="checkbox" id="payatcounter" name="paymenttype" className="form-check-input" />
                                                                <label className="form-check-label d-block" htmlFor="payatcounter">Pay at counter<small className="text-muted float-end">168</small></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </aside>
                                    {/* Side Panel end */}
                                    <div className="col-lg-9 mt-4 mt-lg-0">
                                        {/* Sort Filters
    ============================================= */}
                                        <div className="border-bottom mb-3 pb-3">
                                            <div className="row align-items-center">
                                                <div className="col-sm-6 col-md-8"> <span className="me-3"><span className="fw-600">{vehicles.length} Cars</span> found</span> <span className="text-warning text-nowrap">Prices inclusive of taxes</span></div>
                                            </div>
                                        </div>
                                        {/* Sort Filters end */}
                                        {/* List Item
    ============================================= */}
                                        <div className="car-list">
                                            <div className="row align-items-center g-4 mt-2">
                                                {/* {transfers.length > 0 && transfers.map(v =>
                                                (!v.transferVehicle.vehicleName.startsWith("Amadeus") ?
                                                    <div className="col-md-6">
                                                        <div className="card shadow-sm border-0 mb-4"><img className="img-fluid rounded align-top" src={v.transferVehicle.imageUrl} alt="cars" style={{ maxHeight: "150px", minHeight: "150px", maxWidth: "300px" }} />
                                                            <div className="card-body">
                                                                <h4 className="align-items-center"><p className="text-dark text-5 me-2">{v.transferVehicle.vehicleName}</p></h4>
                                                                <p className="text-dark text-5">{v.transferType}</p>
                                                                <p className="car-features d-flex align-items-center text-4">
                                                                    <span data-bs-toggle="tooltip" title="Seats" className="p-2">
                                                                        <i className="fas fa-user p-1" /><small className="text-2">{v.transferVehicle.seat}</small>
                                                                    </span>
                                                                    <span data-bs-toggle="tooltip" title="Bags" className="p-2">
                                                                        <i className="fas fa-suitcase-rolling p-1" /><small className="text-2">{v.transferVehicle.baggage}</small>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div className="card-footer bg-transparent d-flex align-items-center">
                                                                <div className="text-dark text-7 fw-500 me-2 me-lg-3">${v.totalPrice}</div>
                                                                <Link onClick={(e) => handleCar(e, v)} className="btn btn-sm btn-primary ms-auto">SELECT</Link> </div>
                                                        </div>
                                                    </div> : "")
                                                )} */}
                                                {vehicles.length > 0 && vehicles.map(v =>
                                                (!v.transferVehicle.vehicleName.startsWith("Amadeus") ?
                                                    <div className="col-xl-12 col-lg-12 col-md-12">Tanisha
                                                        <div className="card list-layout-block rounded-3 p-3">
                                                            <div className="row">
                                                                <div className="col-xl-4 col-lg-3 col-md">
                                                                    <div className="cardImage__caps rounded-2 overflow-hidden h-100">
                                                                        <img className="img-fluid h-100 object-fit" src={v.transferVehicle.imageUrl} alt="image" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl col-lg col-md">
                                                                    <div className="listLayout_midCaps mt-md-0 mt-3 mb-md-0 mb-3">
                                                                        <h4 className="fs-5 fw-bold mb-1">{v.transferVehicle.vehicleName}</h4>
                                                                        <ul className="row gx-2 p-0 excortio">
                                                                            <li className="col-auto">
                                                                                <p className="text-muted-2 text-md">{v.transferVehicle.category}</p>
                                                                            </li>
                                                                            <li className="col-auto">
                                                                                <p className="text-muted-2 text-md fw-bold">.</p>
                                                                            </li>
                                                                            <li className="col-auto">
                                                                                <p className="text-muted-2 text-md">{v.transferVehicle.vehicleCode}</p>
                                                                            </li>
                                                                            <li className="col-auto">
                                                                                <p className="text-muted-2 text-md fw-bold">.</p>
                                                                            </li>
                                                                            <li className="col-auto">
                                                                                <p className="text-muted-2 text-md">{v.transferVehicle.seat} Seats</p>
                                                                            </li>
                                                                        </ul>
                                                                        <div className="detail ellipsis-container mt-3">
                                                                            <span className="ellipsis">{v.transferVehicle.baggage} Large Bag</span>
                                                                        </div>
                                                                        <div className="position-relative mt-3">
                                                                            <div className="fw-medium text-dark">{v.distance} {v.distanceType} included.</div>
                                                                        </div>
                                                                        <div className="position-relative mt-4">
                                                                            <div className="d-block position-relative"><span className="label bg-light-success text-success">{v.transferCancellationRules[0].description}</span></div>                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-auto col-lg-auto col-md-auto text-right text-md-left d-flex align-items-start align-items-md-end flex-column">
                                                                    <div className="position-relative mt-auto full-width">
                                                                        <div className="d-flex align-items-center justify-content-start justify-content-md-end mb-1">
                                                                            <span className="label bg-success text-light">15% Off</span>
                                                                        </div>
                                                                        <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                                                                            <div className="text-dark fw-bold fs-3">INR {v.totalPrice}</div>
                                                                        </div>
                                                                        <div className="d-flex align-items-start align-items-md-end text-start text-md-end flex-column">
                                                                            <Link onClick={(e) => handleCar(e, v)} className="btn btn-md btn-primary full-width fw-medium px-lg-4">SELECT<i className="fa-solid fa-arrow-trend-up ms-2" /></Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>: "")
                                                )}
                                            </div>
                                        </div>
                                        {/* List Item end */}
                                    </div>
                                </div>

                            </section>
                        </div>
                    </section>
                    <div id="select-busseats" className="modal fade" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Bus Booking Details</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                </div>
                                <div className="modal-body">
                                    <div className="bus-details">
                                        <div className="row align-items-sm-center flex-row mb-4">
                                            <div className="col col-sm-3"> <span className="text-4 text-dark operator-name">AK Tour &amp; Travels</span> <span className="text-muted d-block">AC Sleeper</span> </div>
                                            <div className="col col-sm-3 text-center time-info"> <span className="text-5 text-dark">12:00</span> <small className="text-muted d-block">Mumbai</small> </div>
                                            <div className="col col-sm-3 text-center d-none d-sm-block time-info"> <span className="text-3 duration">06h 32m</span> <small className="text-muted d-block">12 Stops</small> </div>
                                            <div className="col col-sm-3 text-center time-info"> <span className="text-5 text-dark">05:15</span> <small className="text-muted d-block">Surat</small> </div>
                                        </div>
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item"> <a className="nav-link active" id="first-tab" data-bs-toggle="tab" href="#first" role="tab" aria-controls="first" aria-selected="true">Available Seats</a> </li>
                                            <li className="nav-item"> <a className="nav-link" id="second-tab" data-bs-toggle="tab" href="#second" role="tab" aria-controls="second" aria-selected="false">Cancellation Fee</a> </li>
                                        </ul>
                                        <div className="tab-content my-3" id="myTabContent">
                                            <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 text-center">
                                                        <p className="text-muted text-1">Click on Seat to select/ deselect</p>
                                                        <div id="seat-map">
                                                            <div className="front-indicator">Front</div>
                                                        </div>
                                                        <div id="legend" />
                                                    </div>
                                                    <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                                                        <div className="booking-details">
                                                            <h2 className="text-5">Booking Details</h2>
                                                            <p>Selected Seats (<span id="counter">0</span>):</p>
                                                            <ul id="selected-seats">
                                                            </ul>
                                                            <div className="d-flex bg-light-4 px-3 py-2 mb-3">Total Fare: <span className="text-dark text-5 fw-600 ms-2">$<span id="total">0</span></span></div>
                                                            <div className="d-grid">
                                                                <button className="btn btn-primary">Continue</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="second" role="tabpanel" aria-labelledby="second-tab">
                                                <table className="table table-hover table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Hours before Departure</th>
                                                            <th className="text-center">Refund Percentage</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Before 0 Hrs.</td>
                                                            <td className="text-center">0%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Before 24 Hrs.</td>
                                                            <td className="text-center">30%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Before 48 Hrs.</td>
                                                            <td className="text-center">60%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Before 60 Hrs.</td>
                                                            <td className="text-center">75%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Above 60 Hrs. </td>
                                                            <td className="text-center">80%</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p className="fw-bold">Terms &amp; Conditions</p>
                                                <ul>
                                                    <li>The penalty is subject to 24 hrs before departure. No Changes are allowed after that.</li>
                                                    <li>The charges are per seat.</li>
                                                    <li>Partial cancellation is not allowed on tickets booked under special discounted fares.</li>
                                                    <li>In case of no-show or ticket not cancelled within the stipulated time, only statutory taxes are refundable subject to Service Fee.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            }
        </div>
    )
}