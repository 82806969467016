import { useEffect, useRef, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HomeBanner from "../Home/HomeBanner";
import Login from "../Authentication/Login";
import PromoCodes from "../Master/PromoCode";
import airports from '../Data/AirportList.json';
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
export default function SearchCar() {
    const navigate = useNavigate();
    const [travelerDisplay, setTravelerDisplay] = useState(false);
    const [from, setFrom] = useState('Airport');
    const [to, setTo] = useState('Accomdation');

    const [csvData, setCsvData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    const [addressFrom, setAddressFrom] = useState('Shamshabad Rajiv Gandhi Intl Arpt');
    const [airportFrom, setAirportFrom] = useState('Shamshabad Rajiv Gandhi Intl Arpt');
    const [airportCodeFrom, setAirportCodeFrom] = useState('HYD');
    const [cityFrom, setCityFrom] = useState('Hyderabad');
    const [countryFrom, setCountryFrom] = useState('India');
    const [countryCodeFrom, setCountryCodeFrom] = useState('IN');
    const [pinCodeFrom, setPinCodeFrom] = useState("0");
    const [latitudeFrom, setLatitudeFrom] = useState("0");
    const [longitudeFrom, setLongitudeFrom] = useState("0");

    const [addressTo, setAddressTo] = useState('');
    const [airportTo, setAirportTo] = useState('');
    const [airportCodeTo, setAirportCodeTo] = useState('');
    const [cityTo, setCityTo] = useState('');
    const [countryTo, setCountryTo] = useState('');
    const [countryCodeTo, setCountryCodeTo] = useState('');
    const [pinCodeTo, setPinCodeTo] = useState("");
    const [latitudeTo, setLatitudeTo] = useState("");
    const [longitudeTo, setLongitudeTo] = useState("");


    const [travelDate, setTravelDate] = useState(new Date());
    const [adults, setAdults] = useState(1);
    const [kids, setKids] = useState(0);
    const [errors, setErrors] = useState({});
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const validate = () => {
        let temp = {};
        temp.addressFrom = addressFrom === "" ? false : true;
        temp.addressTo = addressTo === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    function getLocationFrom() {
        const fromLocation = {
            "fullName": airportFrom,
            "airportName": airportFrom,
            "airportCode": airportCodeFrom,
            "city": cityFrom,
            "country": countryFrom,
            "countryCode": countryCodeFrom,
            "address": addressFrom,
            "pinCode": pinCodeFrom,
            "latitude": latitudeFrom,
            "longitude": longitudeFrom
        }
        return fromLocation;
    }
    function getLocationTo() {
        const toLocation = {
            "fullName": airportTo,
            "airportName": airportTo,
            "airportCode": airportCodeTo,
            "city": cityTo,
            "country": countryTo,
            "countryCode": countryCodeTo,
            "address": addressTo,
            "pinCode": pinCodeTo,
            "latitude": latitudeTo.toString(),
            "longitude": longitudeTo.toString()
        }
        return toLocation;
    }
    const handleSearch = (e) => {
        e.preventDefault();
        if (validate()) {
            const searchQuery = {
                "from": from,
                "to": to,
                "adults": adults,
                "kids": kids,
                "fromLocation": getLocationFrom(),
                "toLocation": getLocationTo(),
                "travelDate": moment(travelDate).format('YYYY-MM-DD'),
                "passengers": parseInt(adults) + parseInt(kids),
            }
            console.log(searchQuery)
            navigate('/cars/' + adults + "/" + kids + "/" + moment(travelDate).format('YYYY-MM-DD'), { state: { searchQuery } });
        }
    }
    const handleDisplayClick = () => {
        setTravelerDisplay(travelerDisplay => !travelerDisplay);
    }
    const handleIncreaseAdults = (e) => {
        setAdults(parseFloat(adults + 1))
    }
    const handleDecreaseAdults = (e) => {
        if (adults !== 1) {
            setAdults(parseFloat(adults - 1))
        }
    }
    const handleIncreaseKids = (e) => {
        setKids(parseFloat(kids + 1))
    }
    const handleDecreaseKids = (e) => {
        if (kids !== 0) {
            setKids(parseFloat(kids - 1))
        }
    }
    const handleDepartureDate = (date) => {
        if (date !== null) {
            setTravelDate(date);
        }
    }
    const [departureResults, setDepartureResults] = useState([]);
    const [returnResults, setReturnResults] = useState([]);
    const inputDRef = useRef(null);
    const inputRRef = useRef(null);
    const handleDepartureAirportSearch = (e) => {
        const value = e.target.value;
        setAddressFrom(value);
        if (value.length > 0) {
            const filteredAirports = airports
                .filter(airport =>
                    airport.airportCode.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 10);
            setDepartureResults(filteredAirports);
            if (filteredAirports.length === 0) {
                const filteredAirports1 = airports
                    .filter(airport =>
                        airport.cityName.toLowerCase().includes(value.toLowerCase())
                    )
                    .slice(0, 10);
                setDepartureResults(filteredAirports1);
            }
        } else {
            setDepartureResults([]);
        }
    };
    const handleDepartureAirportClick = (e) => {
        setReturnResults([]);
        let filteredAirports = airports.filter(function (p) {
            return p.display === true
        }).slice(0, 10);
        setDepartureResults(filteredAirports);
        inputDRef.current.select();
    };
    const handleDepartureSelect = (data) => {
        setAddressFrom(data.airportName);
        setAirportFrom(data.airportName);
        setAirportCodeFrom(data.airportCode);
        setCityFrom(data.cityName);
        setCountryFrom(data.countryName);
        setCountryCodeFrom(data.countryCode);
        setDepartureResults([]);
    }
    const handleReturnAirportSearch = (e) => {
        const value = e.target.value;
        if (value.length > 2) {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: APIConfig.APIACTIVATEURL + APIConfig.AUTOHOTELSEARCH + "?Keyword=" + value,
            };

            axios.request(config)
                .then((response) => {
                    console.log(response.data.response.data.data);
                    setReturnResults(response.data.response.data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setReturnResults([]);
        }
        setAddressTo(value);
    };
    const handleReturnSelect = (data) => {
        setAddressTo(data.name,data.address?.cityName,data.address?.countryCode);
        setAirportTo(data.name);
        setAirportCodeTo(data.iataCode);
        setCityTo(data.address?.cityName);
        setCountryTo(data.address?.countryCode);
        setCountryCodeTo(data.address?.countryCode);
        setLatitudeTo(data.geoCode?.latitude);
        setLongitudeTo(data.geoCode?.longitude);
        setReturnResults([]);
    }
    useEffect(() => {
        if (localStorage.getItem("userId") !== null) {
            setUserId(localStorage.getItem("userId"))
        }
    }, [])
    return (
        <div id="main-wrapper">
            <Header></Header><div className="clearfix"></div>
            <div className="image-cover hero-header bg-white" style={{ background: 'url(/assets/img/2151022224.jpg)' }} data-overlay={1}>
                <div className="container">
                    {/* Search Form */}
                    <div className="row justify-content-between align-items-center g-4">
                        <HomeBanner></HomeBanner>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="searchableForm bg-white rounded-3 py-3 px-4">
                                <div className="searchableCaps">
                                    <div className="search-wrap">
                                        <div className="tab-content pt-4">
                                            <div className="tab-pane show active" id="flights">
                                                <div className="row gx-lg-2 g-3">
                                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                        <div className="form-group hdd-arrow mb-0">
                                                            <h6 className="text-dark">ARIPORT</h6>
                                                            <input ref={inputDRef} className="form-control searchTextbox" type="text" placeholder="Search for an airport" value={addressFrom} onChange={handleDepartureAirportSearch} onClick={handleDepartureAirportClick} onFocus={handleDepartureAirportClick} />
                                                            <span className="icon-inside">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={17} fill="#808080" viewBox="0 0 20 17"><path d="M1.376 16.498v-1h17v1h-17zm1.575-5.075L.676 7.498l.85-.2 1.8 1.55 5.4-1.45-4.05-6.85L5.75.223l6.85 6.125 5.225-1.4c.383-.1.73-.03 1.038.212.308.242.462.554.462.938 0 .266-.075.5-.225.7-.15.2-.35.333-.6.4L2.95 11.423z" /></svg>
                                                            </span>
                                                            {errors.displayFrom === false ? (<div className="validationerror">Please select departure </div>) : ('')}
                                                            {departureResults.length > 0 && (
                                                                <ul className="locationListVideo scrollable-list" style={{ minWidth: "350px" }}>
                                                                    {departureResults.map((airport) => (
                                                                        <li className="locationListVideoItem" key={airport.airportCode} onClick={() => handleDepartureSelect(airport)}>
                                                                            <div className="item-name">{airport.cityName} ({airport.airportCode})</div>
                                                                            <div className="item-details">
                                                                                <span className="item-code-date">{airport.airportName}</span>
                                                                                <span className="item-designation">{airport.countryName}</span>
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                        <div className="form-groupp hdd-arrow mb-0">
                                                            <h6 className="text-dark">HOTEL</h6>
                                                            <div className="position-relative">
                                                                <input ref={inputRRef} className="form-control" type="text" placeholder="Search for an hotel" value={addressTo} onChange={handleReturnAirportSearch} />
                                                                <span className="icon-inside">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="#808080" viewBox="0 0 24 24"><mask id="mask0_160_1644" style={{ maskType: 'alpha' }} width={24} height={24} x={0} y={0} maskUnits="userSpaceOnUse"><path fill="#808080" d="M0 0H24V24H0z" /></mask><g mask="url(#mask0_160_1644)"><path d="M3.5 20.5v-1h17v1h-17zm15.475-5.1L3.5 11.125V6.7l.8.225L5 9.05l5.5 1.55V2.65l1.125.275 2.75 8.75 5.25 1.475c.267.067.48.209.638.425.158.217.237.459.237.725 0 .384-.162.692-.487.925-.325.234-.671.292-1.038.175z" /></g></svg>
                                                                </span>
                                                                {returnResults.length > 0 && (
                                                                    <ul className="locationListVideo scrollable-list" style={{ minWidth: "350px" }}>
                                                                        {returnResults.map((airport) => (
                                                                            <li className="locationListVideoItem" key={airport.id} onClick={() => handleReturnSelect(airport)}>
                                                                                <div className="item-name">{airport.name}, {airport.address?.cityName}</div>
                                                                                <div className="item-details">
                                                                                    <span className="item-code-date">{airport.address?.cityName}, {airport.address?.countryCode}</span>
                                                                                </div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                )}
                                                            </div>
                                                            {errors.displayTo === false ? (<div className="validationerror">Please select arrival </div>) : ('')}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                        <h6 className="text-dark">TRAVEL DATE</h6>
                                                        <div className="position-relative">
                                                            <ReactDatePicker autoComplete="false" className="form-control" wrapperClassName="datePicker" name="departureDate" selected={travelDate} onChange={(date) => handleDepartureDate(date)} dateFormat="EEEE, dd MMM yyyy" minDate={new Date()} />
                                                            <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                                            {errors.travelDate === false ? (<div className="validationerror">Please select departureDate </div>) : ('')}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                        <h6 className="text-dark">TRAVELLERS</h6>
                                                        <div className="form-group mb-0">
                                                            <div className="booking-form__input guests-input mixer-auto" style={{ top: "60px !important" }}>
                                                                <button name="guests-btn" id="guests-input-btn" className={travelerDisplay === true ? "open" : ""} onClick={handleDisplayClick}>{adults + " Adults, " + kids + " Kids"}</button>
                                                                <div className={travelerDisplay === true ? "guests-input__options open" : "guests-input__options"} id="guests-input-options">
                                                                    <div>
                                                                        <span className="guests-input__ctrl minus" id="adults-subs-btn" onClick={handleDecreaseAdults}><i className="fa-solid fa-minus" /></span>
                                                                        <span className="guests-input__value"><span defaultValue={1} readOnly value={adults} name="adults">{adults}</span>Adults</span>
                                                                        <span className="guests-input__ctrl plus" id="adults-add-btn" onClick={handleIncreaseAdults}><i className="fa-solid fa-plus" /></span>
                                                                    </div>
                                                                    <div>
                                                                        <span className="guests-input__ctrl minus" id="children-subs-btn" onClick={handleDecreaseKids}><i className="fa-solid fa-minus" /></span>
                                                                        <span className="guests-input__value"><span defaultValue={0} readOnly value={kids} name="kids">{kids}</span>Kids</span>
                                                                        <span className="guests-input__ctrl plus" id="children-add-btn" onClick={handleIncreaseKids}><i className="fa-solid fa-plus" /></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 position-relative" style={{ paddingTop: "25px" }}>
                                                        <button type="button" onClick={e => handleSearch(e)} className="btn btn-primary full-width fw-medium"><i className="fa-solid fa-magnifying-glass me-2"></i>SEARCH</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </row> */}
                </div>
            </div>
            <PromoCodes></PromoCodes>
            <Footer></Footer>
            <Login></Login>
        </div>
    );
}