import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import APIConfig, { APIACTIVATEURL } from "../Configuration/APIConfig";
import PageLoader from "../Layout/PageLoader";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export default function Product() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [searchData, setSearchData] = useState({});
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const [product, setProduct] = useState({});
    const [inclusions, setInclusions] = useState([]);
    const [exclusions, setExclusions] = useState([]);
    const [itineraries, setItineraries] = useState([]);
    const [informations, setInformations] = useState([]);
    const [cancellations, setCancellations] = useState([]);
    const [productImages, setProductImages] = useState([]);
    const [productPickup, setProductPickup] = useState({});
    const [productTicket, setProductTicket] = useState({});
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const BindPageData = () => {
        setLoading(true);
        const searchQuery = {
            "productId": params.productId
        }
        GetProduct(searchQuery);
    }
    const GetProduct = (searchQuery) => {
        axios
            .post(APIConfig.APIACTIVATEURL + APIConfig.GETPRODUCT, searchQuery, { ...headerconfig })
            .then((response) => {
                var responseData = response.data.response;
                if (responseData.succeeded) {
                    setProduct(responseData.data);
                    console.log(responseData.data);
                    if (responseData.data !== null) {
                        const filteredInclusions = responseData.data.productInclusions.filter(
                            (inclusion) => inclusion.description && inclusion.description.trim() !== ""
                          );
                          const filteredExclusions = responseData.data.productExclusions.filter(
                            (inclusion) => inclusion.description && inclusion.description.trim() !== ""
                          );
                        setInclusions(filteredInclusions)
                        setExclusions(filteredExclusions)
                        setInformations(responseData.data.productInformation)
                        setItineraries(responseData.data.productItineraries)
                        setCancellations(responseData.data.productCancellationPolicy)
                        setProductImages(responseData.data.productImages)
                        setProductPickup(responseData.data.productPickup)
                        setProductTicket(responseData.data.productTicketInformation)
                    }
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            }).catch(function (error) {
                setLoading(false);
            });
    };
    const handleBook = () => {
        const searchQuery = {
            "sightSeeingBookingId": "00000000-0000-0000-0000-000000000000",
            "userId": "00000000-0000-0000-0000-000000000000",
            "supplierId": "12c185a6-6e6e-4e03-bb05-9c48354e54de",
            "bookingNo": "",
            "discount": 0,
            "couponCode": "",
            "supplierBookingId": params.productId,
            "productName": product.productName,
            "transactionNo": "",
            "bookingDate": new Date(),
            "noOfAdults": params.adults,
            "noOfKids": params.kids,
            "travelDate": params.travelDate,
            "city": params.destination,
            "travellerName": "",
            "bookingStatus": "PENDING",
            "paymentStatus": "PENDING",
            "travellerInfos": [],
            "invoiceNo": "",
            "transactionFee": 0,
            "totalPrice": searchData.salePrice,
            "gst": 0,
            "gstAmount": 0,
            "paymentGatewayFee": 0,
            "markup": 0,
            "customerPrice": searchData.salePrice,
            "bookingType": "SIGHTSEEING",
            "gatewayAmount": 0,
            "walletAmount": 0,
            "creditAmount": 0,
            "paymentGatewayNo": "",
            "paymentGateway": "RAZORPAY",
            "email": "",
            "mobile": "",
            "requestedBy": "CUSTOMER",
            "price": searchData.salePrice,
            "currencyCode": searchData.currencyCode,
        }
        navigate("/productbooking/" + params.productId + "/" + params.destinationCode + "/" + params.destination + "/" + params.adults + "/" + params.kids + "/" + params.travelDate, { state: { searchQuery }});
    }
    useEffect(() => {
        if (state === null) {
            navigate('/search-activities')
        }
        else if (params === null) {
            navigate('/search-activities')
        }
        else if (state.searchQuery === null) {
            navigate('/search-activities')
        }
        else {
            if (localStorage.getItem("userId") !== null) {
                setUserId(localStorage.getItem("userId"))
            }
            setLoading(false);
            setSearchData(state.searchQuery);
            BindPageData();
        }
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <div className="py-2 gray-simple position-relative">
                        <div className="container">
                            {/* Search Form */}
                            <div className="row justify-content-center align-items-center">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item"><Link to={"/"} className="text-primary">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Activities</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="gray-simple">
                        <div classname="container">
                            <div classname="row justify-content-between gy-4 gx-xl-4 gx-lg-3 gx-md-3 gx-4">
                                <div id="content">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="bg-white shadow-md rounded p-3 p-sm-4 confirm-details">
                                                    <div className="owl-carousel owl-theme single-slider mb-3" data-animateout="fadeOut" data-animatein="fadeIn" data-autoplay="true" data-loop="true" data-autoheight="true" data-nav="true" data-items={1}>
                                                        <Carousel responsive={responsive}>
                                                            {productImages.length > 0 && productImages.map(pi =>
                                                                <div className="item"><Link to={pi.imageUrl} target="_blank"><img className="img-fluid" src={pi.imageUrl} alt="Hotel photo" style={{ minWidth: "800px" }} /></Link></div>
                                                            )}
                                                        </Carousel>
                                                    </div>
                                                    <nav id="navbar-sticky" className="bg-white pb-2 mb-2 sticky-top smooth-scroll">
                                                        <ul className="nav nav-tabs">
                                                            <li className="nav-item"> <a className="nav-link active text-nowrap" href="#known-for">Information</a> </li>
                                                            <li className="nav-item"> <a className="nav-link" href="#inclusions">Inclusions</a> </li>
                                                            <li className="nav-item"> <a className="nav-link" href="#exclusions">Exclusion</a> </li>
                                                            <li className="nav-item"> <a className="nav-link" href="#cancellations">Cancellation</a> </li>
                                                            <li className="nav-item"> <a className="nav-link" href="#itineraries">Itinerary</a> </li>
                                                        </ul>
                                                    </nav>
                                                    <p id="known-for">{product.description}</p>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12">
                                                            <ol className="simple-ul">
                                                                {informations.length > 0 && informations.map(x =>
                                                                    <li className="mb-2">{x.description}</li>
                                                                )}
                                                            </ol>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="featured-box style-1">
                                                        <div className="featured-box-icon text-muted"> <i className="far fa-comments" /></div>
                                                        <h4 className="fw-400 text-4 mb-1">Staff Speaks</h4>
                                                        <p>English</p>
                                                    </div>
                                                    {/* Known For end */}
                                                    <hr className="mb-4" />
                                                    <h4 id="inclusions" className="text-6 mb-4 mt-2">Inclusions</h4>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12">
                                                            <ol className="simple-ul">
                                                                {inclusions.length > 0 && inclusions.map(x =>
                                                                    <li className="mb-2">{x.description}</li>
                                                                )}
                                                            </ol>
                                                        </div>
                                                    </div>
                                                    <hr className="mb-4" />
                                                    <h4 id="exclusions" className="text-6 mb-4 mt-2">Exclusions</h4>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12">
                                                            <ol className="simple-ul">
                                                                {exclusions.length > 0 && exclusions.map(x =>
                                                                    <li className="mb-2">{x.description}</li>
                                                                )}
                                                            </ol>
                                                        </div>
                                                    </div>
                                                    <hr className="mb-4" />
                                                    <h4 id="cancellations" className="text-6 mb-4 mt-2">Cancellation</h4>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12">
                                                            <p className="mb-2">{cancellations.description}</p>
                                                        </div>
                                                    </div>
                                                    <hr className="mb-4" />
                                                    <h4 id="itineraries" className="text-6 mb-4 mt-2">Itinerary</h4>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12">
                                                            <ol className="simple-ul">
                                                                {itineraries.length > 0 && itineraries.map(x =>
                                                                    <li className="mb-2">{x.description}</li>
                                                                )}
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <aside className="col-lg-4 mt-4 mt-lg-0">
                                                <div className="bg-white shadow-md rounded p-3 mb-4">
                                                    <div className="position-relative ps-4">
                                                        <div className="location-brief-line"> <em className="location-brief-pickup" /> <em className="location-brief-dropoff" /> </div>
                                                        <h6 className="text-4 mb-2">Name</h6>
                                                        <ul className="list-unstyled">
                                                            <li className="mb-1">{product.productName}</li>
                                                        </ul>
                                                    </div>
                                                    <div className="position-relative ps-4">
                                                        <div className="location-brief-line"> <em className="location-brief-pickup" /> <em className="location-brief-dropoff" /> </div>
                                                        <h6 className="text-4 mb-2">Pick-up</h6>
                                                        <ul className="list-unstyled">
                                                            <li className="mb-1">{productPickup.pickUpType}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="bg-white shadow-md rounded p-3 sticky-top">
                                                    <h6 className="text-5 mb-3">Price Summary</h6>
                                                    <hr className="mx-n3" />
                                                    <ul className="list-unstyled">
                                                        <li className="mb-2">Price <span className="float-end text-5 fw-500 text-dark">{searchData.currencyCode} {searchData.salePrice}</span><br />
                                                            <small className="text-muted lh-1">{productTicket.ticketTypeDescription}</small></li>
                                                        <li className="mb-2">Duration <span className="text-success"></span> <span className="float-end text-5 fw-500 text-dark">{searchData.duration}</span></li>
                                                    </ul>
                                                    <hr />
                                                    <div className="text-dark text-4 fw-500 my-4"> Total Amount<span className="float-end text-9">{searchData.currencyCode} {searchData.salePrice}</span></div>
                                                    <hr />
                                                    <div className="form-check mb-3">
                                                        <input type="checkbox" id="terms" name="termsConditions" className="form-check-input" />
                                                        <label className="form-check-label d-block" htmlFor="terms">I agree with the Terms &amp; Conditions.</label>
                                                    </div>
                                                    <div className="d-grid">
                                                        <button className="btn btn-primary" type="submit" onClick={() => handleBook()}>Book Now</button>
                                                    </div>
                                                    <p className="text-danger text-center mt-3 mb-1"><i className="far fa-clock" /> Last Booked - 6 hours ago</p>
                                                </div>
                                                {/* Price Summary End */}
                                            </aside>
                                            {/* Side Panel End */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div></section>
                    <Footer></Footer>
                </div >
            }
        </div >
    )
}