// StarRating.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faStar as faStarEmpty } from '@fortawesome/free-solid-svg-icons';

const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const halfStars = rating % 1 >= 0.5 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStars;

  return (
    <div>
      {Array(fullStars).fill().map((_, i) => (
        <FontAwesomeIcon key={`full-${i}`} icon={faStar} className='ratingstar' />
      ))}
      {halfStars === 1 && <FontAwesomeIcon icon={faStarHalfAlt} className='ratingstar' />}
      {Array(emptyStars).fill().map((_, i) => (
        <FontAwesomeIcon key={`empty-${i}`} icon={faStarEmpty} className='ratingstar' />
      ))}
    </div>
  );
};

export default StarRating;