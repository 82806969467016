import Header from "../Layout/Header";
import React, { useEffect, useState, useRef } from "react";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import PageLoader from "../Layout/PageLoader";
export default function TransferBooking() {
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [booking, setBooking] = useState('');
    const [from, setFrom] = useState({});
    const [to, setTo] = useState({});
    const [vehicleData, setVehicleData] = useState({});
    const contentRef = useRef();
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const handlePrint = useReactToPrint({
        content: () => contentRef.current,
    });
    const downloadPDF = () => {
        const input = contentRef.current;

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('transferbooking.pdf');
        });
    };
    const GetDetails = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETTRANSFERDETAILS + "/" + params.transferBookingId, { ...headerconfig })
            .then((response) => {
                setLoading(false)
                setBooking(response.data.response.data);
                var data = response.data.response.data;
                if (data.fromLocation !== null) {
                    setFrom(JSON.parse(data.fromLocation))
                }
                if (data.toLocation !== null) {
                    setTo(JSON.parse(data.toLocation))
                }
                if (data.vehicleData !== null) {
                    setVehicleData(JSON.parse(data.vehicleData))
                }
            });
    };
    useEffect(() => {
        if (localStorage.getItem('userId') === null) {
            localStorage.clear();
            navigate("/");
        }
        else {
            setLoading(false);
            GetDetails();
        }
    }, [])
    return (
        <>
        {loading === true ? 
            <PageLoader></PageLoader> :
            <div id="main-wrapper">
                <Header></Header>
                <div className="clearfix"></div>
                <div className="gray-simple py-3">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="card mb-3">
                                    <div className="car-body px-xl-5 px-lg-4 py-4 px-2">
                                        <div ref={contentRef}>
                                            <div className="d-flex align-items-center justify-content-center flex-column text-center mb-5 mt-2">
                                                <h3 className="mb-0">Booking Confirmation!</h3>
                                                <span>Help Line : {APIConfig.COMAPNYPHONE}</span>
                                            </div>
                                            <p style={{ paddingLeft: "20px" }}>Dear Traveler, Following are the transfer booking details from {booking.from} to {booking.to}</p>
                                            <div className="card" style={{ textAlign: "start" }}>
                                                <div className="card-body">
                                                    <div className="row mb-3">
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Booking No</b> : {booking.bookingNo}</label>
                                                        </div>
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Travel Date</b> : {moment(booking.travelDate).format('DD MMM YYYY')}</label>
                                                        </div>
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Booking Status</b> : {booking.bookingStatus}</label>
                                                        </div>
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Payment Status</b> : {booking.paymentStatus}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-6 mb-3">
                                                            <label><b>From</b> : {from.FullName} ({from.AirportCode}), {from.City}, {from.Country}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-6 mb-3">
                                                            <label><b>To</b> : {to.FullName}, {to.Address} {to.City}, {to.Country}</label>
                                                        </div>
                                                    </div>
                                                    <h4 className="mb-3">Vehicle Details</h4>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Vehicle</b> : {vehicleData.TransferVehicle?.VehicleName}</label>
                                                        </div>
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Type</b> : {vehicleData.TransferVehicle?.VehicleCode}</label>
                                                        </div>
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Transfer Type</b> : {vehicleData.TransferType}</label>
                                                        </div>
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Duration</b> : {vehicleData.Duration} mins</label>
                                                        </div>
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Distance</b> : {vehicleData.Distance} {vehicleData.DistanceType}</label>
                                                        </div>
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Baggage</b> : {vehicleData.TransferVehicle?.Baggage}</label>
                                                        </div>
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Seats</b> : {vehicleData.TransferVehicle?.Seat}</label>
                                                        </div>
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Service Provider</b> : {vehicleData.TransferServiceProvider?.Name}</label>
                                                        </div>
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Driver Name</b> : {vehicleData.TransferServiceProvider?.DriverName}</label>
                                                        </div>
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Phone Number</b> : {vehicleData.TransferServiceProvider?.PhoneNumber}</label>
                                                        </div>
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Email</b> : {vehicleData.TransferServiceProvider?.Email}</label>
                                                        </div>
                                                        <div className="col-lg-3 mb-3">
                                                            <label><b>Price</b> : {vehicleData.TotalPrice} INR</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center d-flex align-items-center justify-content-center">
                                            <button onClick={downloadPDF} className="btn btn-md btn-light-primary fw-semibold mx-2">Download Voucher</button>
                                            <button onClick={handlePrint} className="btn btn-md btn-light-primary fw-semibold mx-2">Print Voucher</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <DownloadFlightVoucher ref={contentRef} flightBookingId={params.flightBookingId}></DownloadFlightVoucher> */}
            </div>
        }
        <div style={{height:"100px"}}></div>
        </>
    )
}