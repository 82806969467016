import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
export default function Refund() {
    return (
        <div id="main-wrapper">
            <div>
                <AgentHeader></AgentHeader>
                <div className="clearfix"></div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Refund Policy</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="pt-3">
                    <div className="container">
                        <div className="bg-white shadow-md rounded p-4">
                        <h4 className="text-6">Refund Policy - Land Air Travels</h4>
                            <div className="row mt-4 mb-2">
                                <div className="col-md-12">
                                    <p>Refunds will be processed as per the airline fare rules and cancellation policy. Such refunds shall be subject to LAND AIR Travels receiving the same from the airlines. However, the convenience fee paid to LAND AIR Travels paid at the time of booking is a non-refundable fee.</p>
                                    <p>All cancellations made directly with the airline need to be intimated to Land Air Travels, in order to initiate the process of refund. The processing time for refunds may vary depending on the mode of payment, bank etc. The refund shall be processed after deducting the LAND AIR Travels service fee which is independent of the convenience fee as mentioned above.</p>
                                    <p>The refund will be credited to the same account from which the payment was made. For example, if the User used a credit card, LAND AIR Travels will make an appropriate charge reversal to the same credit card; like-wise if the User used a debit card, LAND AIR Travels will credit the money to the same debit card.</p>
                                    <p>In the event of cancellation and refund of partially utilized tickets, upfront discount and promo code discount availed at the time of booking would be deducted from the refund amount.</p>                                    
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>What is your cancellation policy?</h4>
                                        <p>Cancellation policies vary depending on the airline. You can find detailed information during the booking process, and we recommend reviewing the terms and conditions before finalizing your reservation.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>How will I get my money back after a cancellation?</h4>
                                        <p>We will credit the money back to the same account you used while making the booking. For example, if you used your credit card, we will make an appropriate charge reversal. If you used your debit card, we will credit the money back to the debit card.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>How long does it take to process a refund?</h4>
                                        <p>We usually process the refund within 4 working days of cancellation. However, it may take slightly longer to reflect in your account statement depending upon your bank. We’ve noticed that it takes about 14 working days for most refunds to hit their respective accounts.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>I still haven’t received my refund. Help!</h4>
                                        <p>Please email us support@landairtravels.com or contact us @ +91 7995331116 with booking details</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>What if I encounter issues during my trip?</h4>
                                        <p>Our customer support team is available 24/7 to assist you. Contact us through the provided helpline or email, and we will promptly address any concerns or issues you may have during your journey.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}