import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import APIConfig, { APIACTIVATEURL } from "../Configuration/APIConfig";
import PageLoader from "../Layout/PageLoader";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export default function ProductConfirmation() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [searchData, setSearchData] = useState({});
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const [product, setProduct] = useState({});
    const [inclusions, setInclusions] = useState([]);
    const [exclusions, setExclusions] = useState([]);
    const [itineraries, setItineraries] = useState([]);
    const [informations, setInformations] = useState([]);
    const [cancellations, setCancellations] = useState([]);
    const [productPickup, setProductPickup] = useState({});
    const [productTicket, setProductTicket] = useState({});
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const BindPageData = () => {
        setLoading(true);
        const searchQuery = {
            "productId": params.productId
        }
        GetProduct(searchQuery);
    }
    const GetProduct = (searchQuery) => {
        axios
            .post(APIConfig.APIACTIVATEURL + APIConfig.GETPRODUCT, searchQuery, { ...headerconfig })
            .then((response) => {
                var responseData = response.data.response;
                if (responseData.succeeded) {
                    setProduct(responseData.data);
                    console.log(responseData.data);
                    if (responseData.data !== null) {
                        setInclusions(responseData.data.productInclusions)
                        setExclusions(responseData.data.productExclusions)
                        setInformations(responseData.data.productInformation)
                        setItineraries(responseData.data.productItineraries)
                        setCancellations(responseData.data.productCancellationPolicy)
                        setProductPickup(responseData.data.productPickup)
                        setProductTicket(responseData.data.productTicketInformation)
                    }
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            }).catch(function (error) {
                setLoading(false);
            });
    };
    useEffect(() => {
        if (state === null) {
            navigate('/search-activities')
        }
        else if (params === null) {
            navigate('/search-activities')
        }
        else if (state.searchQuery === null) {
            navigate('/search-activities')
        }
        else {
            if (localStorage.getItem("userId") !== null) {
                setUserId(localStorage.getItem("userId"))
            }
            setLoading(false);
            setSearchData(state.searchData);
            BindPageData();
        }
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <div className="py-2 gray-simple position-relative">
                        <div className="container">
                            {/* Search Form */}
                            <div className="row justify-content-center align-items-center">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item"><Link to={"/"} className="text-primary">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Activities</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="gray-simple">
                        <div classname="container">
                            <div classname="row justify-content-between gy-4 gx-xl-4 gx-lg-3 gx-md-3 gx-4">
                                <div id="content">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="bg-white shadow-md rounded p-3 p-sm-4 confirm-details">
                                                    <div className="featured-box style-1 mb-3">
                                                        <h4 className="fw-400 text-4 mb-3">Booking Confirmation</h4>
                                                        <h6 className="fw-400 text-4 mb-3">{product.productName}, {params.destination}</h6>
                                                        <p className="fw-400 text-4 mb-3"><b>Duration</b> : {searchData.duration}</p>
                                                        <p className="fw-400 text-4 mb-1"><b>Pickup</b> : {productPickup.pickUpType}</p>
                                                    </div>
                                                    <p id="known-for">{product.description}</p>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12">
                                                            <ol className="simple-ul">
                                                                {informations.length > 0 && informations.map(x =>
                                                                    <li className="mb-2">{x.description}</li>
                                                                )}
                                                            </ol>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="featured-box style-1">
                                                        <div className="featured-box-icon text-muted"> <i className="far fa-comments" /></div>
                                                        <h4 className="fw-400 text-4 mb-1">Staff Speaks</h4>
                                                        <p>English</p>
                                                    </div>
                                                    {/* Known For end */}
                                                    <hr className="mb-4" />
                                                    <h4 id="inclusions" className="text-6 mb-4 mt-2">Inclusions</h4>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12">
                                                            <ol className="simple-ul">
                                                                {inclusions.length > 0 && inclusions.map(x =>
                                                                    <li className="mb-2">{x.description}</li>
                                                                )}
                                                            </ol>
                                                        </div>
                                                    </div>
                                                    <hr className="mb-4" />
                                                    <h4 id="exclusions" className="text-6 mb-4 mt-2">Exclusions</h4>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12">
                                                            <ol className="simple-ul">
                                                                {exclusions.length > 0 && exclusions.map(x =>
                                                                    <li className="mb-2">{x.description}</li>
                                                                )}
                                                            </ol>
                                                        </div>
                                                    </div>
                                                    <hr className="mb-4" />
                                                    <h4 id="cancellations" className="text-6 mb-4 mt-2">Cancellation</h4>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12">
                                                            <p className="mb-2">{cancellations.description}</p>
                                                        </div>
                                                    </div>
                                                    <hr className="mb-4" />
                                                    <h4 id="itineraries" className="text-6 mb-4 mt-2">Itinerary</h4>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12">
                                                            <ol className="simple-ul">
                                                                {itineraries.length > 0 && itineraries.map(x =>
                                                                    <li className="mb-2">{x.description}</li>
                                                                )}
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div></section>
                    <Footer></Footer>
                </div >
            }
        </div >
    )
}