import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
export default function Contact() {
    return (
        <div id="main-wrapper">
            <div>
                <AgentHeader></AgentHeader>
                <div className="clearfix"></div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><Link to={"/"} className="text-primary">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="pt-3">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-md-6">
                                <div className="bg-white shadow-md rounded h-100 p-3">
                                    <iframe className="h-100 w-100" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15226.135470883475!2d78.4551716!3d17.4341436!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9a16ffffffff%3A0xe0ac70b267ec2468!2sLand%20Air%20Travels%20PVT.LTD.!5e0!3m2!1sen!2sin!4v1721624300525!5m2!1sen!2sin" allowFullScreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bg-white shadow-md rounded p-4">
                                    <h2 className="text-6 mb-4">Get in touch</h2>
                                    <hr className="mx-n4 mb-4" />
                                    <p className="text-3">For Customer Support and Query, Get in touch with us:</p>
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"></div>
                                        <h3>Land Air Travels</h3>
                                        <p>GF-3, Tourism Plaza, 6-3-869/A,<br />
                                        Greenlands, Begumpet,<br />
                                         Hyderabad,<br /> 
                                         Telangana 500016</p>
                                    </div>
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"></div>
                                        <h3>Telephone</h3>
                                        <p>(+91) 7995331116</p>
                                    </div>
                                    <div className="featured-box style-1">
                                        <div className="featured-box-icon text-primary"></div>
                                        <h3>Travel Inquiries</h3>
                                        <p>support@landairtravels.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}